.image-upload {
    position: relative;
}

.image-container {
    position: relative;
    width: 100%;
    height: 100%;
}

.image-container img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    object-fit: cover;
    display: block;
}
.image-container-2{
    display: flex;
    justify-content: center;
    align-items: center;
}

.image-container-2 img {
    width: 100%;
    height: 100%;
    border-radius: 5px;
    object-fit: cover;
    display: block;
}

.upload-icon {
    position: absolute;
    /* bottom: 5px;
    right: 5px; */
    width: 30px;
    height: 30px;
    border-radius: 50%;
    /* background-color: #007bff; */
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 18px;
    cursor: pointer;
    /* box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.2); */
}

#file-input {
    display: none;
}
