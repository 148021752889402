.custom-categories-btn {
  display: flex;
  align-items: center;
}

.custom-categories-dropdown .categories-grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr); 
  gap: 10px; 
  padding: 10px;
  margin: 10px;

}

.categories {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  padding: 10px;
  text-align: center;
  width: 110px;
  border:1px solid #00000080;
  border-radius: 6px;
}
.categories-trip {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  padding: 10px;
  text-align: center;
  width: 114px;
  border:1px solid #00000080;
  border-radius: 4px;
}
.categories-image{
  width: 50px;
  height: 50px;
}
.categories-image img{
  width: 80%;
  height: auto;
  object-fit: cover;
}
.categories span{
  font-size: 12px;
  font-weight: 700;
  color: #E94560;
}
.categories-trip span{
  font-size: 12px;
  font-weight: 700;
  color: #979797;
}

.categories-card .categories.selected {
  background-color: #FEF4F4; 
  border:1px solid #E94560;
}
.categories-trip.selected {
  background-color: #FEF4F4; 
  border:1px solid #E94560;
}
.categories-trip.selected span{
  color: #E94560;
}

.categories-btn-icon {
  margin-left: auto;
}

.categories-btn-container {
  padding: 10px;
}


@media (max-width: 576px) {


}