/* TripForm.css */

.stepper {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.step-wrapper {
    display: flex;
    align-items: center;
}


.step {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #f0f0f0;
    cursor: pointer;
    background-color: #bfbfbf;
    transition: background-color 0.3s ease;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    margin-left: -10px;
    position: relative;
    z-index: 2;
}

.step.completed {
    background-color: #E94560;
}

.step.active {
    background-color: #E94560;
    color: white;
}

.step-number {
    width: 25px;
    height: 25px;
    border-radius: 50%;
    background-color: #ffffff;
}

.step-title-0,
.step-title-1,
.step-title-2,
.step-title-3 {
    margin-top: 5px;
    font-size: 22px;
    font-weight: 700;
    line-height: 26px;
    position: absolute;
    top: -40px;
}

.step-title-0 {}

.step-title-1 {
    margin-left: -2.5%;
}

.step-title-2 {
    margin-left: -2%;
}

.step-title-3 {
    margin-left: -2%;
}


.step-content-0,
.step-content-1,
.step-content-2,
.step-content-3 {
    margin-bottom: 20px;
}

.step-content-0-main {
    min-height: 500px;
}

.step-content-0 {
    margin: 0rem 10rem 2rem 10rem;
}

#create-trip-container .was-validated .form-control:invalid,
#create-trip-container .form-control.is-invalid {
    border-color: #ed2000;
    padding-right: calc(1.49em + 1rem);
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23ed2000'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23ed2000' stroke='none'/%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-position: right calc(0.3725em + 0.25rem) center;
    background-size: calc(0.745em + 0.5rem) calc(0.745em + 0.5rem);
}

#create-trip-container .was-validated .no_of_days.form-control:invalid,
#create-trip-container .no_of_days.form-control.is-invalid {
    border-color: #ed2000;
    background-image: none;
    padding: 1rem;
}

.step-navigation {
    display: flex;
    justify-content: center;
    border: 1px solid #E94560;
    border-radius: 4px;
    gap: 20px;
}

button {
    padding: 10px 20px;
    margin: 10px;
    border: none;
    background-color: #007bff;
    color: white;
    cursor: pointer;
}

button:disabled {
    background-color: #ccc;
    cursor: not-allowed;
}

textarea {
    width: 100%;
    margin: 16px 0;
}

.step-connector {
    flex: 1 1 auto;
}

.step-connector span {
    display: block;
    border-color: rgb(191, 191, 191);
    border-top-style: solid;
    border-top-width: 20px;
    left: -5px;
    position: relative;
}

.steppr-line.completed-line {
    border-color: #E94560;
}

.inner-container-0 {
    margin: 0rem 10rem 0rem 10rem;
}

.inner-container-1 {
    margin: 0rem 5rem 0rem 5rem;
}

.categories-container {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    /* Adjust the gap between items as needed */
}

.months-container {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
}

.months-trip {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    padding: 12px 10px;
    text-align: center;
    /* width: 105px; */
    border: 1px solid #00000080;
    border-radius: 4px;
}

.months-trip span {
    font-size: 12px;
    font-weight: 700;
    color: #979797;
}

.months-trip.selected {
    background-color: #FEF4F4;
    border: 1px solid #E94560;

}

.months-trip.selected span {
    color: #E94560;
}

.trip-details-form .accordion-button::after,
.creator-profile-form .accordion-button::after {
    width: 22px;
    height: 22px;
    content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'%3E%3Cpath fill='%236A757E' d='M233.4 406.6c12.5 12.5 32.8 12.5 45.3 0l192-192c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L256 338.7 86.6 169.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l192 192z'/%3E%3C/svg%3E");
    background-image: none;
}

.trip-details-form .accordion-button:not(.collapsed)::after,
.creator-profile-form .accordion-button:not(.collapsed)::after{
    background-image: none;
    content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'%3E%3Cpath fill='%236A757E' d='M233.4 406.6c12.5 12.5 32.8 12.5 45.3 0l192-192c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L256 338.7 86.6 169.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l192 192z'/%3E%3C/svg%3E");
}

.trip-details-form .accordion-item {
    border-bottom: none;
    margin-bottom: 2rem;
    border: 1px solid #000000;
    border-radius: 4px !important;
}

.trip-details-form .accordion-item:not(:first-of-type) {
    border-top: 1px solid #000000;
}

.inclusive-exclusive {
    border: 1px solid #E94560;
    border-radius: 4px;
    padding: 10px;
}

.seo-ie-additional {
    font-size: 24px;
    font-weight: 600;
    line-height: 30px;
    color: #000000cc;
}

.trip-details-form .vertical-tab .nav-link.active::after {
    content: none;
}

.vertical-tab .nav-link.custom-tab-nav-item {
    height: 40px;
    font-size: 18px;
    font-weight: 600;
}

.vertical-tab .nav-link.custom-tab-nav-item.active {
    background-color: #e945604d;
    color: #E94560;
    font-size: 18px;
    font-weight: 600;
}

.vertical-tab .custom-tab-item {
    border: 1px solid #E94560;
    border-radius: 4px;
}

#create-trip-container .about-trip-select__control {
    margin-bottom: 0;
}

.about-trip-select-container.is-invalid .about-trip-select__control {
    border-color: #ed2000;
}
@media (max-width: 1540px) {
    .inner-container-0{
        margin: 0rem 0rem 0rem 0rem;
    }
}
@media (max-width: 1440px) {
    .trip-details-form .vertical-tab .nav-link.custom-tab-nav-item {
        font-size: 14px;
    }

    .trip-details-form .vertical-tab .nav-link.custom-tab-nav-item.active {
        font-size: 14px;
    }
}


@media (max-width: 1200px) {

    .inner-container-0,
    .inner-container-1 {
        margin: 0rem 0rem 0rem 0rem;
    }

    .step-content-0 {
        margin: 0rem 0rem 2rem 0rem;
    }

    .trip-details-form .vertical-tab .nav-link.custom-tab-nav-item {
        font-size: 14px;
    }

    .trip-details-form .vertical-tab .nav-link.custom-tab-nav-item.active {
        font-size: 14px;
    }
}

@media (max-width: 992px) {
    .inner-container-0 {
        margin: 0rem 0rem 0rem 0rem;
    }

    .step-content-0 {
        margin: 0rem 0rem 2rem 0rem;
    }

    .vertical-tab .nav-link.custom-tab-nav-item {
        font-size: 16px;
    }

    .vertical-tab .nav-link.custom-tab-nav-item.active {
        font-size: 16px;
    }
}

@media (max-width: 575.82px) {
    .tab-horizontal-sm {
        justify-content: space-between;
        border-bottom: none;
    }

    .custom-tab-item {
        width: 100%;
    }

    .vertical-tab .nav-link.custom-tab-nav-item {
        display: flex;
        align-items: center;
        justify-content: center;
    }
}