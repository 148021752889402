
.custom-pricerange-dropdown .dropdown-menu{
  width: 400px;
  padding: 20px 20px 30px 20px !important
}

.rc-slider-rail,
.rc-slider-track, .rc-slider-tracks,
.rc-slider-step{
  height: 10px !important;
}
.rc-slider-rail{
  background-color: #d9d9d9 !important;
}
.rc-slider-handle{
  height: 20px !important;
  width: 20px !important;
}
.rc-slider-track, .rc-slider-tracks{
  background-color: #E94560 !important;

}

.rc-slider-handle{
  border: solid 2px #E94560 !important;
}

.rc-slider-handle-dragging.rc-slider-handle-dragging.rc-slider-handle-dragging {
  border-color: #E94560 !important;
  box-shadow: none !important;
}


.pricerange-btn-container{
  margin-top: 25px;
}
