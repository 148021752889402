body {
  background-color: #ffffff !important;
}

.navbar-top {
  border-bottom: none !important;
  box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.36);
}

.navbar-horizontal .nav-main-menu .navbar-nav-top .nav-link {
  font-size: 18px;
  font-weight: 700;
  line-height: 22px;
  height: 64px;
}

.navbar-horizontal
  .nav-main-menu
  .navbar-nav-top
  .dropdown
  .dropdown-toggle::after {
  display: none;
  /*small down arrow of small device menu*/
}

.navbar-horizontal .nav-main-menu .navbar-nav-top .nav-link:hover {
  background-color: #ffffff !important;
  color: #e94560;
}

/* .nav-main-menu .nav-item {
    border-bottom: 5px solid #ffffff;
} */

/* .nav-main-menu .nav-item.active {
    border-bottom: 5px solid #E94560;
} */
.nav-main-menu .nav-item.active::after {
  content: "";
  position: absolute;
  left: 1.25rem;
  right: 1.5rem;
  /* Matches the padding-right */
  bottom: 0;
  height: 5px;
  background-color: #e94560;
}

.navbar-horizontal .nav-main-menu .nav-item.active .nav-link {
  color: #e94560;
}

.content {
  background-color: #fff;
}

.custom-destination-dropdown .btn-primary,
.custom-categories-dropdown .btn-primary,
.custom-pricerange-dropdown .btn-primary,
.trip-search-btn .btn-primary,
.custom-daterange-dropdown .btn-primary {
  background-color: #e94560;
  font-size: 1rem;
  font-weight: 700;
  color: #fff;
}

.header-btn .btn-primary {
  background-color: #e945601f;
  font-weight: 600;
  color: #000000;
  margin-right: 15px;
  line-height: 20px;
}

.header-btn .btn-primary,
.custom-btn-size .btn-primary,
.custom-btn-size.big-font .btn-primary,
.custom-btn-size .btn-outline-primary,
.custom-tab-nav-items .nav-item .nav-link {
  font-size: 1.25rem;
}

.custom-btn-small .btn-primary,
.custom-btn-small .btn-outline-primary {
  font-size: 1rem;
}

.custom-destination-dropdown .btn-outline-primary,
.custom-categories-dropdown .btn-outline-primary,
.custom-pricerange-dropdown .btn-outline-primary,
.trip-search-btn .btn-outline-primary,
.custom-daterange-dropdown .btn-outline-primary {
  border-color: #e94560;
  color: #e94560;
  font-size: 14px;
  font-weight: 500;
}

.header-btn .btn-outline-primary {
  border-color: #e94560;
  color: #e94560;
  font-size: 1.25rem;
  font-weight: 600;
}

.custom-btn-size .btn-primary {
  background-color: #e94560;
  font-weight: 700;
  color: #fff;
  height: 52px;
  padding-left: 20px;
  padding-right: 20px;
}

.custom-btn-small .btn-primary {
  background-color: #e94560;
  color: #fff;
}

.custom-btn-small .btn-outline-primary {
  border-color: #e94560;
  color: #e94560;
}

.custom-btn-size.big-font .btn-primary {
  font-weight: 600;
}

.custom-btn-trip .btn-primary {
  background-color: #e94560;
  font-size: 15px;
  font-weight: 700;
  color: #fff;

  width: 100%;
}

.trip-search-btn .btn-primary {
  height: 42px;
}

.horizontal-second-slide {
  background: url(../img/travel/crausal/vertical-crousal/pexels-cottonbro-4551839\ 1.png)
    top center;
  background-size: cover;
  position: relative;
}

.tags-container-view {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.tags-trip.selected {
  background-color: #fef4f4;
  border: 1px solid #e94560;
  border-radius: 4px;
  text-decoration: none;
  color: #e94560;
}

.view-all-trips .custom-link {
  color: #e94560;
  text-decoration: none !important;
}

.tags-trip-link:hover,
.tags-trip-link:active,
.tags-trip-link:visited,
.custom-link:hover,
.custom-link:active,
.custom-link:visited {
  color: #e94560;
  text-decoration: none;
}

.tags-trip {
  padding: 5px 8px;
}

.custom-destination-dropdown .btn-primary:hover,
.custom-categories-dropdown .btn-primary:hover,
.custom-pricerange-dropdown .btn-primary:hover,
.trip-search-btn .btn-primary:hover,
.custom-daterange-dropdown .btn-primary:hover {
  border-color: #e94560;
  background-color: #e94560;
  color: #fff;
}

.header-btn .btn-primary:hover {
  background-color: #e945601f;
  border-color: #e94560;
  color: #000000;
}

.header-btn .btn:focus,
.trip-search-btn .btn:focus {
  border-color: #e94560;
  background-color: #e94560;
  color: #fff;
}

.header-btn .btn:disabled {
  background-color: #e94560;
}

.header-btn .btn:focus {
  background-color: #e945601f;
  border-color: #e94560;
  color: #000000;
}

.custom-destination-dropdown .btn:focus,
.travel-passport-modal .btn:focus {
  border: 1px solid #e94560;
  background-color: #e94560;
}

.rdrCalendarWrapper {
  border-radius: 10px;
}

.custom-categories-dropdown .btn:focus,
.custom-daterange-dropdown .btn:focus,
.custom-pricerange-dropdown .btn:focus,
.custom-destination-dropdown .btn:focus {
  border: 1px solid #e94560;
  background-color: #ffffff;
  color: #e94560;
}

.header-btn .btn-outline-primary:hover,
.custom-destination-dropdown .btn-outline-primary:hover,
.custom-categories-dropdown .btn-outline-primary:hover,
.custom-pricerange-dropdown .btn-outline-primary:hover,
.trip-search-btn .btn-outline-primary:hover,
.custom-daterange-dropdown .btn-outline-primary:hover {
  border-color: #e94560;
  background-color: #e94560;
  color: #fff;
}

.custom-btn-circle .rounded-circle {
  background-color: #e94560 !important;
}

.custom-btn-size .btn-primary:hover {
  border-color: #e94560;
  background-color: #e94560;
  color: #fff;
}

.custom-btn-small .btn-primary:hover {
  border-color: #e94560;
  background-color: #e94560;
  color: #fff;
}

.custom-btn-size .btn-outline-primary {
  border-color: #e94560;
  color: #000000;
  border-radius: 6px;
  height: 54px;
  font-weight: 500;
}

.custom-btn-size .btn-outline-primary:hover {
  border-color: #e94560;
  background-color: #e94560;
  color: #fff;
}

.custom-btn-size .btn-outline-primary:focus,
.custom-btn-size .btn:focus,
.custom-btn-size .btn-outline-primary:disabled,
.custom-btn-size .btn:disabled,
.custom-btn-size .btn.btn-primary.dropdown-toggle:not(:disabled) {
  border-color: #e94560;
  background-color: #e94560;
  color: #fff;
}

.custom-btn-small .btn-primary:focus {
  border-color: #e94560;
  background-color: #e94560;
  color: #fff;
}

.custom-btn-small .btn-outline-primary:focus,
.custom-btn-small .btn-outline-primary:hover {
  border-color: #e94560;
  background-color: #e94560;
  color: #fff;
}

.custom-btn-size .dropdown-item.active {
  background-color: #e9456026;
  color: #000000;
}

.custom-btn-trip .btn-primary:hover {
  border-color: #e94560;
  background-color: #e94560;
  color: #fff;
}

.footer-nav-list {
  list-style: none;
  padding: 0;
}

.footer {
  background-color: #e9eef4;
  height: auto !important;
  border-top: none !important;
}

.footer-padding {
  padding-top: 3rem;
}

.footer-nav-category {
  font-size: 22px;
  color: #000000;
}

.footer-nav-list li {
  text-align: left;
  margin-bottom: 5px;
}

.footer-nav-list li span {
  font-size: 22px;
  color: #000000;
}

.footer-nav-list li span:hover {
  color: #e94560;
  cursor: pointer;
}

.footer-social {
  width: 44px;
  height: 44px;
  border-radius: 50%;
  border: 1px solid #e5e5e5;
  background-color: #e5e5e5;
  background-image: linear-gradient(
    to bottom right,
    transparent 50%,
    #f2efef 50%
  );
}

.copy-right-text {
  font-size: 15px;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.8);
}

.equal-image img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.brand-icon-heading {
  font-weight: 400;
  font-size: 30px;
  line-height: 50px;
}

.brand-icons-section {
  padding-top: 20px;
  /* Adjust as needed */
  padding-bottom: 20px;
  /* Adjust as needed */
}

#hero {
  margin-top: 64px;
  width: 100%;
  height: calc(100vh - 210px);
  /* background: url(../img/travel/hero/hero-bg.jpg) top center; */
  background-size: cover;
  position: relative;
}

#hero-common {
  margin-top: 10px;
  width: 100%;
  height: 495px;
  /* background: url(../img/travel/hero/hero-bg.jpg) top center; */
  background-size: cover;
  position: relative;
  margin-top: 64px;
}

#hero-faq {
  margin-top: 10px;
  width: 100%;
  height: 450px;
  background-repeat: no-repeat;
  background-size: cover !important;
  margin-top: 64px;
  background-position: center !important;
  max-width: 100% !important;
}

#create-trip-container {
  margin-top: 64px;
}

.creators #hero-creators {
  margin-top: 64px;
  width: 100%;
  height: calc(100vh - 150px);
  background-size: cover !important;
  max-width: 100% !important;
  position: relative;
}

.search-trip-card {
  padding: 2rem 2rem 2rem 2rem;
  z-index: 99;
}

.search-field {
  max-width: 12rem;
}

.search-trip-card .form-icon-container .form-icon {
  top: 14px;
}

.search-trip-card .form-icon-container .user-filter-icon {
  top: 10px;
}

body .content {
  min-height: unset;
  /* or whatever value you want */
  padding: 0rem !important;
  /* or your custom padding */
  position: relative;
  padding-bottom: 0rem !important;
  /* or your custom padding bottom */
}

#hero .play-btn::after {
  content: "";
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translateX(-40%) translateY(-50%);
  width: 0;
  height: 0;
  border-top: 30px solid transparent;
  border-bottom: 30px solid transparent;
  border-left: 40px solid #fff;
  z-index: 100;
  transition: all 400ms cubic-bezier(0.55, 0.055, 0.675, 0.19);
}

#hero .play-btn {
  width: 132px;
  height: 132px;
  margin: 0 auto;
  /* background: radial-gradient(#009961 50%, rgba(0, 153, 97, 0.4) 52%); */
  background: #e94560;
  border-radius: 50%;
  display: block;
  overflow: hidden;
  position: relative;
}

.high-light {
  color: #e94560;
}

.high-light-red {
  color: #e94560;
}

.high-light-white {
  color: #ffffff;
}

.high-light-black {
  color: #000000;
}

.high-light-black-light {
  color: #00000080;
}

.light-black {
  color: #000000cc;
}

.hero-heading {
  font-size: 86px;
  line-height: 104px;
}

.main-heading {
  font-size: 56px;
  line-height: 70px;
}

.sub-heading {
  font-size: 30px;
  line-height: 36px;
}

.trip-title-big {
  font-size: 46px;
  line-height: 56px;
  font-weight: 700;
  color: #000000;
}

.number-big {
  font-size: 86px;
  font-weight: 700;
  color: #00000029;
  line-height: 86px;
}

.creator-sub-heading {
  padding-top: 45px;
  padding-bottom: 45px;
}

.creator-profile-cont {
  margin-top: 64px;
}

.created-by {
  font-size: 16px;
  line-height: 18px;
  font-weight: 400;
  color: #000000;
}

.creator-title,
.ie-heading {
  font-size: 18px;
  line-height: 20px;
  font-weight: 700;
  color: #000000;
}

.created-by-agent {
  width: 90%;
}

.created-by-agent p {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.trip-price {
  font-size: 20px;
  font-weight: 700;
  line-height: 36px;
  color: #000000;
}

.creator-disc {
  font-size: 16px;
  line-height: 30px;
  font-weight: 400;
  color: #000000cc;
}

.brand-title {
  font-size: 30px;
  line-height: 50px;
  font-weight: 400;
  color: #00000080;
  padding-top: 30px;
}

.bcm-creator .brand-title {
  line-height: 36px;
}

.bcm-creator {
  margin: 0rem 0rem 4rem 6rem;
}

.travel-category {
  font-size: 24px;
  line-height: 36px;
  word-wrap: break-word;
}

.slide-img-container {
  width: 100%;
  height: 380px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  margin-bottom: 20px;
}

.slide-img-container img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 10px;
}

.unique-container {
  background-color: #fff5f5;
}

.tips-padding {
  padding-top: 70px;
  padding-bottom: 70px;
}

.tips-count-padding {
  padding-top: 70px;
  padding-bottom: 0px;
}

.link-hover:hover {
  cursor: pointer;
}

.trips {
  display: flex;
  justify-content: flex-end;
}

.trips-count div {
  width: 45px;
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 10px;
  padding-bottom: 10px;
  background-color: #e94560;
  font-size: 27px;
  font-weight: 700;
  color: #ffffff;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.view-all-trips {
  font-size: 22px;
  font-weight: 700;
  line-height: 27px;
  color: #e94560;
}

.view-all-trips:hover {
  text-decoration: underline;
}

.card-trip {
  border-radius: unset !important;
  border-color: #00000080;
}

.card-trip .card-img,
.card-img-top {
  border-radius: unset !important;
}

.trip-view-custom {
  padding-top: 80px;
  padding-bottom: 80px;
}

.modal-close {
  margin-top: -2rem;
}

.modal-close,
.modal-close-profile {
  background-color: #e94560;
  width: 20px;
  height: 20px;
  text-align: center;
  border-radius: 50%;
  color: #fff9f8;
}

.modal-close:hover,
.modal-close-profile:hover {
  cursor: pointer;
}

.modal-close svg,
.modal-close-profile svg {
  margin-bottom: 2px;
}

.sign-up-modal .form-label {
  padding-left: 0px;
  font-size: 18px;
  font-weight: 700;
  line-height: 22px;
  color: #000000;
  text-transform: none;
  padding-bottom: 5px;
}

.sign-up-modal .modal-title-custom {
  font-size: 36px;
  line-height: 44px;
  font-weight: 700;
  color: #000000;
  margin-top: 1rem;
  padding-left: 35px;
}

#create-trip-container .form-label {
  padding-left: 0px;
  font-size: 18px;
  font-weight: 400;
  color: #000000cc;
  text-transform: none;
  padding-bottom: 2px;
}

#create-trip-container .form-label.seo-ie-other-label {
  padding-left: 0px;
  font-size: 16px;
  font-weight: 500;
  color: #000000cc;
  text-transform: none;
  padding-bottom: 2px;
}

#create-trip-container .form-check-label {
  padding-left: 0px;
  font-size: 14px;
  font-weight: 500;
  color: #000000cc;
  text-transform: none;
}

#create-trip-container .heading-ie {
  font-size: 16px;
  font-weight: 700;
  line-height: 16px;
}

.travel-style-modal .modal-profile-title,
.travel-passport-modal .header-title-passport p,
.date-picker-container .title-dob {
  font-size: 25px;
  line-height: 30px;
  font-weight: 700;
  color: #000000;
  padding-left: 15px;
}

.sign-up-modal .modal-header,
.travel-style-modal .modal-header {
  border: none;
}

.travel-style-modal .modal-header {
  padding: 30px;
}

.sign-up-modal .modal-body,
.travel-style-modal .modal-body {
  padding-top: 0px;
}

.sign-up-modal .modal-bdy,
.travel-style-modal .modal-profle-bdy {
  margin-left: 35px;
  margin-right: 35px;
  padding-top: 20px;
  margin-top: 10px;
}

.sign-up-modal .modal-bdy {
  border-top: 0.5px solid #c4c4c4;
}

.travel-style-modal .modal-dialog {
  padding: 50px;
}

.travel-style-modal .modal-body {
  margin-left: 30px;
  margin-right: 30px;
}

.sign-up-modal .form-control,
.sign-up-modal .form-select,
.enquire-now-modal .form-control,
.enquire-now-modal .form-select {
  height: 54px;
  border: 1px solid #e94560;
  border-radius: 0px;
  border-radius: 4px;
}

.trip-search-modal .form-control,
.trip-search-modal .form-select {
  height: 42px;
  border: 1px solid #e94560;
  border-radius: 0px;
  border-radius: 4px;
}

.sign-up-modal .form-control:focus,
.sign-up-modal .form-select:focus,
.enquire-now-modal .form-control:focus,
.enquire-now-modal .form-select:focus,
.enquire-now-modal .form-check-input:focus,
.travel-passport-modal .form-control:focus,
.travel-passport-modal .form-select:focus,
.trip-search-modal .form-control:focus,
.trip-search-modal .form-select:focus {
  border: 1px solid #e94560;
  box-shadow:
    inset 0 1px 1px rgba(233, 69, 96, 0.075),
    0 0 8px rgba(233, 69, 96, 0.6);
}

.enquire-now-modal .modal-dialog {
  margin-top: 0;
  /* Ensures the modal starts from the top */
  margin-bottom: 0;
  /* height: 100%; */
  /* Use the full height of the viewport */
}

/* .enquire-now-modal .modal-content {
    height: 100%;
    overflow-y: auto;
} */

.sign-up-modal .input-icon-color,
.trip-search-modal .input-icon-color {
  color: #e94560;
}

.sign-up-modal .checkbox-label {
  font-size: 12px;
  line-height: 15px;
  font-weight: 400;
  font-style: italic;
  color: #00000080;
}

.sign-up-modal .checkbox-label a {
  color: #e94560;
}

.sign-up-modal .checkbox-label a:hover {
  text-decoration: underline;
  cursor: pointer;
}

.sign-up-modal .form-check-input {
  border: 1px solid #e94560;
}

.sign-up-modal .form-check-input:checked,
.enquire-now-form .form-check-input:checked {
  background-color: #e94560;
  border-color: #e94560;
}

.about-trip-form .form-check-input:checked {
  background-color: #e94560;
  border-color: #e94560;
}

.trip-details-form .form-check-input:checked {
  background-color: #e94560;
  border-color: #e94560;
}

.sign-up-modal .form-check-input:focus,
.trip-details-form .form-check-input:focus,
.about-trip-form .form-check-input:focus,
.enquire-now-form .form-check-input:checked {
  border: 1px solid #e94560;
  box-shadow: none;
}

.travel-style-modal .form-check-input {
  border: 1px solid #e94560;
  width: 20px;
  height: 20px;
}

.travel-style-modal .form-check-input[type="checkbox"] {
  border-radius: 2px;
}

.travel-style-modal .form-check-input:checked {
  background-color: #e94560;
  border-color: #e94560;
}

.fa-google {
  background: conic-gradient(
      from -45deg,
      #ea4335 110deg,
      #4285f4 90deg 180deg,
      #34a853 180deg 270deg,
      #fbbc05 270deg
    )
    73% 55%/150% 150% no-repeat;
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  -webkit-text-fill-color: transparent;
  margin-right: 10px;
}

.fa-facebook {
  color: #1877f2;
  margin-right: 10px;
}

.fa-instagram {
  color: #e94560;
  margin-right: 10px;
}

.custom-btn-size .custom-btn:hover {
  color: white;
}

.custom-profile .dropdown-profile {
  min-width: 10.3125rem;
}

.custom-profile .custom-profile-btn {
  height: 42px;
  border: 1px solid #e94560 !important;
  padding: 5px 5px 5px 5px;
  gap: 10px;
  border-radius: 5px;
  font-size: 20px;
  font-weight: 600;
  color: #1a1a2e;
}

.custom-destination-dropdown .custom-destination-btn,
.custom-categories-dropdown .custom-categories-btn,
.custom-pricerange-dropdown .custom-destination-btn,
.custom-daterange-dropdown .custom-daterange-btn,
.custom-pricerange-dropdown .custom-price-btn {
  height: 42px;
  border: 1px solid #e94560;
  padding: 5px 15px 5px 15px;
  gap: 10px;
  border-radius: 5px;
  font-size: 14px;
  font-weight: 500;
  color: #1a1a2e;
}

.custom-destination-dropdown .custom-destination-btn:hover,
.custom-destination-dropdown .custom-destination-btn.show,
.custom-categories-dropdown .custom-categories-btn:hover,
.custom-categories-dropdown .custom-categories-btn.show,
.custom-pricerange-dropdown .custom-price-btn:hover,
.custom-pricerange-dropdown .custom-price-btn.show,
.custom-daterange-dropdown .custom-daterange-btn:hover,
.custom-daterange-dropdown .custom-daterange-btn.show {
  border: 1px solid #e94560;
}

.profile-btn-icon,
.destination-btn-icon,
.categories-btn-icon {
  color: #e94560;
}

.custom-profile .custom-profile-btn:hover {
  text-decoration: none;
}

.custom-profile .dropdown-profile .nav .nav-link {
  height: 44px;
  font-size: 14px;
  font-weight: 500;
}

.custom-profile .dropdown-profile .nav .nav-link:hover {
  background-color: #e94560;
  color: #ffffff;
}

.custom-profile .dropdown-toggle::after,
.custom-destination-dropdown .dropdown-toggle::after,
.custom-categories-dropdown .dropdown-toggle::after,
.custom-pricerange-dropdown .dropdown-toggle::after,
.custom-daterange-dropdown .dropdown-toggle::after {
  display: none;
  margin-left: 0;
  vertical-align: 0;
  content: "";
  border: none;
}

.destination-card .destination {
  padding: 6px 15px 6px 15px;
  min-width: 220px;
  border: 1px solid #e94560;
  margin: 12px;
  border-radius: 10px;
  font-size: 18px;
  color: #000000;
  font-weight: 500;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.custom-destination-dropdown .dropdown-destination,
.custom-categories-dropdown .dropdown-categories,
.custom-pricerange-dropdown .dropdown-categories,
.dropdown-categories,
.custom-daterange-dropdown .dropdown-daterange {
  border: 1px solid #e94560 !important;
  border-radius: 10px;
}

.destination-icon,
.categories-icon {
  font-size: 12px;
}

.dropdown-destination,
.categories-grid {
  inset: 0px auto auto 0px !important;
}

.dropdown-price {
  right: 0px;
  left: auto;
}

.destination-btn-container,
.categories-btn-container {
  margin: 12px;
}

.trip-reset-btn div {
  color: #e94560;
  height: 42px;
  text-align: center;
  padding: 10px 10px 10px 10px;
}

#trip-card-swiper .swiper-pagination-bullet {
  height: 12px;
  width: 12px;
}

#trip-card-swiper .swiper-pagination-bullet-active {
  background-color: #ffffff;
}

#trip-card-swiper .trip-location {
  display: flex;
  justify-content: space-between;
  width: 100%;
  top: 0;
  position: absolute;
  z-index: 2;
  padding: 15px;
}

#trip-card-swiper .trip-destination span {
  color: #ffffff;
}

#trip-card-swiper .trip-destination span {
  color: #ffffff;
}

.trip-destination:hover {
  color: #e94560 !important;
  text-decoration: none;
}

.trip-destination-icon {
  color: #e94560;
}

.trip-like-icon {
  color: #e94560;
}

.creator-title-details {
  font-size: 56px;
  font-weight: 700;
  line-height: 80px;
  color: #000000;
}

.creator-title-details-video {
  padding-top: 45px;
  padding-bottom: 45px;
  font-size: 52px;
  font-weight: 700;
  line-height: 80px;
  color: #000000;
}

.creator-discription {
  font-size: 22px;
  font-weight: 400;
  line-height: 42px;
}

.creator-discription-video {
  font-size: 28px;
  font-weight: 400;
  line-height: 50px;
}

.creator-discription span {
  font-weight: 700;
}

.chat-icon-btn {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  background-color: #e94560;
  position: fixed;
  bottom: 2.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  z-index: 1046;
  right: 3rem;
  transition:
    width 0.3s ease,
    height 0.3s ease;
}

.chat-icon-btn:hover,
.profile-style:hover {
  cursor: pointer;
}

.chat-icon-btn.btn-chat-close {
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  color: #ffffff;
}

.chat-icon {
  padding-top: 5px !important;
}

/*Trips FAQ*/
.trips-faq .accordion-item {
  border: 1px solid #e94560;
  margin-bottom: 20px;
  border-radius: 4px !important;
  padding: 2rem;
}

.trips-faq .accordion-button::after {
  width: 30px;
  height: 30px;
  content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30'  fill='currentColor' class='bi bi-x' viewBox='-200 -200 900 900'%3E%3Cpath fill='%23E94560' d='M416 208H272V64c0-17.67-14.33-32-32-32h-32c-17.67 0-32 14.33-32 32v144H32c-17.67 0-32 14.33-32 32v32c0 17.67 14.33 32 32 32h144v144c0 17.67 14.33 32 32 32h32c17.67 0 32-14.33 32-32V304h144c17.67 0 32-14.33 32-32v-32c0-17.67-14.33-32-32-32z'%3E%3C/path%3E%3C/svg%3E");
  background-image: none;
}

.trips-faq .accordion-item:not(:first-of-type) {
  border-top: 1px solid #e94560;
}

.trips-faq .accordion-button:not(.collapsed)::after {
  background-image: none;
  content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30'  fill='currentColor' class='bi bi-x' viewBox='0 0 16 16'%3E%3Cpath fill='%23E94560' d='M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z'/%3E%3C/svg%3E");
}

.trips-faq .accordion-button.collapsed,
.trips-faq .faq-main-ques {
  color: #1a1a2e;
  font-size: 24px;
  line-height: 40px;
  font-weight: 600;
}

.trips-faq .faq-items {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.trips-faq .faq-item-ques {
  color: #e94560;
  font-size: 24px;
  line-height: 36px;
  font-weight: 600;
  margin-top: 20px;
  word-wrap: break-word;
}

.trips-faq .faq-item-ans,
.ie-details {
  color: #1a1a2e;
  font-size: 18px;
  line-height: 32px;
  font-weight: 400;
  word-wrap: break-word;
}

.faq-sub-title {
  font-size: 18px;
  line-height: 20px;
  font-weight: 700;
  text-align: center;
  color: #ffffff;
}

.blog-details {
  padding-top: 15px;
  /* padding-bottom: 15px; */
}

.profile-container {
  padding: 0px 75px 0px 75px;
}

.profile {
  background-color: #fff5f5;
}

.profile-box {
  height: 144px;
  width: 90%;
  box-shadow: rgba(0, 0, 0, 0.23) 0px 0px 6px;
  border-radius: 10px;
  background-color: #fff9f8;
}

.profile-box img {
  width: 22px;
}

.profile-box span {
  font-size: 24px;
  font-weight: 700;
  line-height: 30px;
  margin-left: 15px;
}

.profile-box .openText {
  margin-left: 40px;
  margin-top: 24px;
  width: 75%;
}

.profile-box .openText.passport,
.profile-box .openText.dob {
  margin-left: 50px;
}

.profile-box .openText.phone {
  margin-left: 55px;
}

.profile-box input[type="text"] {
  color: #e94560;
  height: 36px;
  padding-left: 10px;
  text-decoration: none;
  background-color: rgba(233, 69, 96, 0.12);
  border: 0;
  border-radius: 6px;
}

.profile-box input[type="date"] {
  color: #e94560;
  height: 36px;
  padding-left: 10px;
  text-decoration: none;
  background-color: rgba(233, 69, 96, 0.12);
  border: 0;
  border-radius: 6px;
}

.profile-box input[type="text"]:focus {
  outline: none;
}

.profile-box .user-group {
  width: 35px;
}

.profile-box .forward-icon {
  width: 30px;
  height: 30px;
  background-color: #e945601f;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
}

.profile-box .update-style p {
  font-size: 12px;
  color: #979797;
  text-decoration: underline;
  margin: 0;
}

/* Track */
::-webkit-scrollbar {
  width: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #e9456033;
  border-radius: 5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #e9456066;
}

/* Track */
::-webkit-scrollbar-track {
  background: #ffffff;
}

/* Handle when dragging */
::-webkit-scrollbar-thumb:active {
  background: #e9456066;
}

.travel-style-img {
  border: 2px solid #ffffff;
  border-radius: 7px;
  padding: 5px;
}

.travel-style-img.active {
  border: 2px solid #e94560;
}

.travel-style-img img {
  object-fit: contain;
  width: 100%;
  border-radius: 6px;
}

.travel-style-check {
  padding: 10px 20px 10px 20px;
  border: 1px solid #e94560;
  margin-top: 10px;
  border-radius: 6px;
}

.travel-style-check.active {
  background-color: #e9456045;
  box-shadow: rgba(0, 0, 0, 0.23) 0px 0px 6px;
}

.travel-style-modal .form-check-input:focus {
  box-shadow: none;
  border-color: #e94560;
}

.travel-style-modal .trip-style-detail {
  margin-top: 20px;
  margin-bottom: 30px;
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
}

.travel-style {
  height: 36px;
  background-color: rgba(233, 69, 96, 0.12);
  color: #e94560;
  font-size: 18px;
  font-weight: 400;
  line-height: 30px;
  padding: 3px 20px 3px 20px;
  text-align: center;
  border-radius: 6px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.style-extra-count {
  color: #e94560;
  font-size: 18px;
  font-weight: 400;
  line-height: 30px;
  text-decoration: underline;
  margin-left: 40px;
  text-align: center;
  cursor: pointer;
}

.selected-travel-style {
  margin-left: 55px;
}

.travel-style-sub,
.travel-passport-sub {
  font-size: 16px;
  font-weight: 300;
  margin-bottom: 20px;
}

.travel-style-sub {
  margin-left: 75px;
}

.travel-style-sub-info {
  margin-left: 30px;
  margin-top: 15px;
  font-size: 18px;
  color: #e94560;
}

.header-title {
  margin-left: 48px;
}

.dashed-seprator {
  display: flex;
  flex-direction: row;
  width: 60%;
  /* border: dashed; */
  margin: auto;
  height: 1px;
  background-image: linear-gradient(
    90deg,
    #00000040,
    #00000040 75%,
    transparent 75%,
    transparent 100%
  );
  background-size: 20px 1px;
  border: none;
}

.profile-box .info {
  font-size: 12px;
  font-weight: 400;
  margin-left: 4px;
  bottom: 0;
}

.travel-passport-modal .header-passport {
  padding: 30px;
}

.travel-passport-modal .modal-body {
  padding: 0px 30px 30px 30px;
}

.travel-passport-modal .modal-header {
  border-bottom: none;
}

.travel-passport-modal .form-control,
.travel-passport-modal .form-select {
  height: 40px;
  border: 1px solid #e94560;
  border-radius: 4px;
  font-size: 20px;
  color: #e94560;
  font-weight: 500;
  padding: 5px 10px 5px 10px;
}

.passport-select-container .passport-select__control,
.passport-select-container .passport-select__control:hover {
  border: 1px solid #e94560;
}

.passport-select-container .passport-select__control {
  border: 1px solid #e94560;
  /* box-shadow: 0 0 0 1px #E94560; */
  box-shadow: none;
}

.about-trip-select-container .about-trip-select__control,
.form-select-container .form-select__control {
  height: 50px;
}

.filter-agent-select-container .filter-agent-select__control {
  height: 42px;
}

.filter-agent-select-container .filter-agent-select__control,
.filter-agent-select-container .filter-agent-select__control:hover {
  border: 1px solid #e94560;
}

.filter-agent-select-container .filter-agent-select__control:focus {
  box-shadow: 0 0 1px #e94560;
  border: 1px solid #e94560;
}

.about-trip-select-container .about-trip-select__control,
.about-trip-select-container .about-trip-select__control:hover {
  border: 1px solid #979797;
}

.about-trip-select-container .about-trip-select__control:focus,
.form-select-container .form-select__control:focus {
  box-shadow: none;
  border: 1px solid #979797;
}

.travel-passport-modal .form-control:focus,
.travel-passport-modal .form-select:focus {
  color: #e94560;
  box-shadow: none;
}

.travel-passport-modal .passport-select__indicator-separator {
  display: none;
}

.travel-passport-modal .form-label {
  font-size: 20px;
  font-weight: 500;
  line-height: 24px;
  color: #1a1a2e;
  text-transform: none;
  padding-left: 0px;
}

.custom-option {
  color: #e94560;
  background-color: #ffffff;
  padding: 15px;
  font-size: 18px;
  line-height: 25px;
}

.custom-option:checked {
  background-color: #e9456045;
  color: #e94560;
}

.date-picker-container {
  position: absolute;
  padding: 30px;
  z-index: 10;
  background-color: #ffffff;
  box-shadow: rgba(0, 0, 0, 0.23) 0px 0px 6px;
  border-radius: 10px;
  width: 420px;
}

.date-picker-container .react-datepicker {
  border: none;
}

.date-picker-container .react-datepicker__header {
  background-color: #ffffff;
  border-bottom: none;
}

.date-picker-container .react-datepicker__day.react-datepicker__day--selected {
  background-color: #e94560;
  border-radius: 0px;
}

.date-picker-container .react-datepicker__day:hover {
  background-color: #f49fac;
  border-radius: 0px;
}

.creator-bg {
  background-color: #fff5f5;
}

.trip-plan-container {
  position: relative;
  display: flex;
  justify-content: flex-end;
}

.trip-bottom-image {
  position: absolute;
  left: 12%;
  top: 25%;
}

.trip-plan-highlight {
  padding: 2px 20px 2px 20px;
  border: 1px solid #e94560;
  font-size: 24px;
  font-weight: 700;
  display: table;
}

.trip-plan-custom {
  padding: 6rem 4rem 9rem 4rem;
}

#create-trip-container .dropzone {
  padding: 1rem;
}

.preview-small {
  width: 110px;
  height: 80px;
}

.dropzone-img-preview-small {
  /* display: ruby; */
  margin-right: 20px;
}

#create-trip-container .tox .tox-sidebar-wrap {
  overflow: hidden;
  border-left: 0px;
  border-right: 0px;
  border-bottom: 0px;
  border-radius: 0px;
}

#create-trip-container .tox-editor-header {
  padding: 5px 0px 0px 20px;
}

.editor-border {
  border: 1px solid #979797;
}

#create-trip-container .form-control,
.enquire-now-form .form-control,
#create-trip-container .activity-duration-type {
  height: 50px;
  border-radius: 4px;
  border-color: #979797;
  font-size: 18px;
}

.enquire-now-form .form-control.text-area-custom {
  height: 100%;
}

#create-trip-container .form-control.in-textarea {
  height: auto;
}

.about-trip-form .form-control:focus {
  box-shadow: none;
}

#create-trip-container .number-of-days.form-control {
  padding-left: 0px;
  padding-right: 0px;
}

/* Hide the default number input spinner */
#create-trip-container input[type="number"]::-webkit-inner-spin-button,
#create-trip-container input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
  padding: 0.5rem 0rem;
}

#create-trip-container input[type="number"] {
  -moz-appearance: textfield;
  /* Firefox */
}

.number-of-days {
  text-align: center;
}

.minus-icon,
.plus-icon {
  font-size: 25px;
  padding: 12px;
  cursor: pointer;
}

.days-containr .minus-icon {
  left: 0rem;
}

.plus-icon {
  right: 0rem;
}

#create-trip-container .minus-icon:hover,
#create-trip-container .plus-icon:hover {
  background-color: #bfbfbf;
}

#create-trip-container .minus-icon:focus,
#create-trip-container .plus-icon:focus {
  background-color: #bfbfbf;
}

.horizontal-tabs .tab-content {
  border: 1px solid #e94560;
  padding: 15px;
  margin-top: 1px;
  position: relative;
}

.horizontal-tabs .nav-item a {
  color: #172e44;
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  border: 1px solid #e7eaee;
}

.horizontal-tabs .nav-underline {
  gap: 10px;
}

.horizontal-tabs .nav-item a.active {
  background-color: #ffffff;
  color: #172e44;
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  border: 1px solid #e94560;
}

.horizontal-tabs .nav-underline .nav-item:first-child .nav-link {
  padding-left: 8px;
}

.form-control.is-invalid ~ .eye-icon2 svg {
  right: 33px !important;
  top: 26px !important;
}

#create-trip-container .minus-icon,
#create-trip-container .plus-icon {
  border-color: #979797;
}

#create-trip-container .plus-icon {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}

#create-trip-container .minus-icon {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}

.error-feedback {
  width: 100%;
  margin-top: 0.25rem;
  font-size: 75%;
  color: #ed2000;
}

.drop-image-preview {
  width: 100px;
}

.error-feedback {
  width: 100%;
  margin-top: 0.25rem;
  font-size: 75%;
  color: #ed2000;
}

.banner-image-preview {
  width: 100%;
  height: auto;
  display: block;
  object-fit: cover;
}

.listing-image-preview {
  /* Style for the image */
  width: 100%;
  height: 100%;
  object-fit: cover;
  /* Ensure images cover their container */
}

.day-itinery-image-container {
  width: 600px;
  overflow: hidden;
}

.listing-image-preview {
  width: 100%;
  height: auto;
  display: block;
  object-fit: cover;
}

.listin-image-main-container {
  display: grid;
  gap: 15px;
  padding-top: 10px;
  padding-bottom: 10px;
  /* Define your grid layout */
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  /* Adjust column width as needed */
}

.listing-image-item {
  /* Ensure items expand to fill the grid cells */
  width: 100%;
}

.banner-image-container {
  position: relative;
  display: inline-block;
}

.image-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 1);
  opacity: 0.3;
  transition: opacity 0.3s ease;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 18px;
}

.itinery-title {
  text-decoration: none;
  color: #000000;
  font-weight: 300;
  margin-top: 5px;
}

.banner-image-container .overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  opacity: 0.3;
  transition: opacity 0.3s ease;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 18px;
}

.loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 10;
  background-color: #f9f9f980;
}

.listing-spinner {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -20px;
  margin-left: -25px;
  width: 50px;
  height: 40px;
  text-align: center;
  font-size: 10px;
}

.image-container:hover .overlay {
  opacity: 1;
  /* Show overlay on hover */
}

.edit-mode-container {
  padding-top: 15px;
  padding-bottom: 15px;
  border-radius: 4px;
  background-color: #f9f9f9b3;
}

.spiner-btn .custom-spiner {
  width: 15px;
  height: 15px;
  color: #1877f2;
}

.spiner-btn.btn:disabled {
  color: #1877f2;
}

.trips-listing-heading {
  margin-top: 64px;
}

.itin-policy .horizontal-tab .nav-item {
  width: 50%;
}

.itin-policy .horizontal-tab .custom-tab-nav-item.nav-link.active {
  /* background-color: #e945604d; */
  color: #e94560;
  font-size: 18px;
  font-weight: 600;
  border-bottom: 2px solid #e94560;
}

.itin-policy .horizontal-tab .custom-tab-nav-item.nav-link {
  color: #3e465b;
  font-size: 18px;
  font-weight: 600;
  border-bottom: none;
  text-align: center;
  border-bottom: 1px solid #d9dee0;
}

.smallLogo {
  width: 60px;
  height: 40px;
}

.navbar-logo .logoMain {
  width: 255px;
  height: auto;
}

.was-validated .form-control:valid ~ .eye-icon2 svg {
  right: 33px !important;
  top: 25px !important;
}

.was-validated .form-control:invalid ~ .eye-icon2 svg {
  right: 33px !important;
  top: 25px !important;
}

.dayInfo {
  font-size: 16px;
}

.day-tab-container {
  background-color: #e9456026;
  padding: 10px;
  padding-left: 10px !important;
}

.trip-image-grid-view {
  justify-content: center;
  display: flex;
  flex-flow: row wrap;
}

.trip-image-grid {
  display: flex;
  flex-flow: row wrap;
  margin-top: 10px;
  max-height: 400px;
  overflow: auto;
  /* Only shows scrollbars when necessary */
  justify-content: center;
  align-items: center;
  min-height: 250px;
}

.galley-image-container {
  position: relative;
  margin: 5px;
}

.trip-image-grid img {
  border: 4px solid #fff;
  width: auto;
  max-width: 16rem;
  height: auto;
  max-height: 8rem;
  border-radius: 0.5rem;
  cursor: pointer;
  z-index: 10;
  backface-visibility: hidden;
  flex: 1 0 8rem;
  position: relative;
  display: block;
}

.gallery-image-check {
  position: absolute;
  z-index: 10;
  left: 8px;
  top: 5px;
}

.cropped-image-view {
  max-width: 90%;
  margin-top: 10px;
}

.custom-search-box .custom-search input {
  border-start-end-radius: 0px;
  border-end-end-radius: 0px;
}

.custom-search-box .custom-search-btn {
  border-start-start-radius: 0px;
  border-end-start-radius: 0px;
}

.confirm-gallery-container {
  padding: 0px 16px;
}

.breif-discription {
  word-wrap: break-word;
}

.filter-container {
  padding: 3rem;
  border: 1px solid red;
  margin-bottom: 2rem;
  border-radius: 4px;
  position: relative;
  z-index: 99;
}

.trips-select-filter-container .trips-select-filter__control,
.trips-select-filter-container .trips-months-filter__control {
  height: 42px;
}

.trips-select-filter-container .trips-select-filter__control {
  margin-bottom: 0px;
}

.trips-select-filter-container .trips-select-filter__control,
.trips-select-filter-container .trips-select-filter__control:hover,
.trips-select-filter-container .trips-months-filter__control,
.trips-select-filter-container .trips-months-filter__control:hover,
.trips-select-filter-container .trips-tags-filter__control,
.trips-select-filter-container .trips-tags-filter__control:hover {
  border: 1px solid #e94560;
}

.trips-select-filter-container .trips-select-filter__control--is-focused,
.trips-select-filter-container .trips-months-filter__control--is-focused,
.trips-select-filter-container .trips-tags-filter__control--is-focused,
.filter-agent-select-container .filter-agent-select__control--is-focused {
  box-shadow: none;
  border: 1px solid #e94560;
}

.trips-select-filter-container .trips-select-filter__dropdown-indicator,
.trips-select-filter-container .trips-select-filter__indicator-separator {
  display: none;
}

.trips-select-filter-container .trips-months-filter__value-container,
.trip-title-filter-containerl .title-filter {
  padding-left: 25px;
}

.trips-select-filter-container .trips-select-filter__value-container {
  padding-left: 40px;
}

#title-filter,
#select-status,
#select-days {
  padding-left: 30px;
  font-size: 16px;
}

.select-option-style {
  font-size: 16px;
  padding-top: 5px !important;
  padding-bottom: 5px !important;
}

.create-new-btn {
  min-width: 205px;
}

.send-for-approval {
  min-width: 275px;
}

.body-enquire-now {
  padding-left: 5rem;
  padding-right: 5rem;
  padding-bottom: 0rem;
}

.step-navigation-enquire {
  display: flex;
  justify-content: center;
  border-radius: 4px;
  gap: 20px;
}

.number-container {
  display: flex;
  gap: 10px;
  position: relative;
}

.numbers-item {
  width: 45px;
  height: 45px;
  border: 1px solid #979797;
  border-radius: 2px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  font-weight: 600;
  cursor: pointer;
}

.numbers-item.selected,
.numbers-item-select.selected {
  background-color: #fef4f4;
  border: 1px solid #e94560;
}

.select-container {
  width: 60px;
  height: 210px;
  overflow-y: scroll;
  z-index: 99;
  border: 1px solid #000;
  position: absolute;
  top: 50px;
  left: 100%;
  background-color: #fff;
}

.numbers-item-select {
  width: 35px;
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin-left: 10px;
  border-radius: 2px;
}

.enquire-now-modal .modal-header {
  border-bottom: none;
  align-items: flex-start;
  padding-bottom: 0px;
}

.enquire-now-modal .modal-body {
  padding-top: 0px;
}

.enquire-now-modal .form-label,
.enquire-now-modal .form-check-label {
  padding-left: 0px;
  font-size: 18px;
  line-height: 30px;
  font-weight: 400;
  color: #000000cc;
  text-transform: none;
  padding-bottom: 2px;
}

.react-datepicker__header {
  background-color: #e945601f;
  color: white;
}

.react-datepicker__day--selected {
  background-color: #e94560;
  color: white;
}

.custom-datepicker-container {
  width: 100%;
}

.custom-datepicker-container .react-datepicker-wrapper {
  width: 100%;
}

.custom-datepicker-container input {
  width: 100%;
  height: 50px;
}

.range-values {
  display: flex;
  gap: 20px;
  font-size: 24px;
  font-weight: 700;
}

.card-body {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

.card-title p,
.trip-price p {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.trip-discription div {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  /* Number of lines to show before truncating */
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 1.5em;
  /* Adjust to your line height */
  max-height: 4.5em;
}

.card-body .d-flex {
  flex-shrink: 0;
}

.custom-share-link .btn-primary,
.custom-share-link .btn-primary:hover,
.custom-share-link .btn-primary:focus,
.custom-share-link .btn-primary.active,
.custom-share-link .btn.show {
  border-color: #e94560;
  background-color: #e94560;
  color: #fff;
}

.custom-sort-dp .btn-primary,
.custom-sort-dp .btn-primary:hover,
.custom-sort-dp .btn-primary:focus,
.custom-sort-dp .btn-primary.active,
.custom-sort-dp .btn.show {
  border-color: #fff;
  background-color: #fff;
  color: #e94560;
}

.item-option-dp:focus,
.item-option-dp:active {
  background-color: #e945601f !important;
  color: #e94560 !important;
  font-weight: 700 !important;
}

.item-option-dp.selected {
  background-color: #e945601f !important;
}

.customCardImg {
  max-height: 320px;
  min-height: 320px;
}

.tags-filter {
  z-index: 99;
}

.banner-card {
  border-bottom-left-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
}

.search-trip-card input::placeholder {
  font-size: 14px !important;
  color: red !important;
}

.profile-inner-container {
  border: 1px solid #000000;
  border-radius: 4px;
  margin-top: 24px;
}

.edit-btn-profile {
  position: relative;
  top: -35px;
  right: 15px;
  z-index: 10;
}

.creator-profile-form .custom-accordian-btn button.collapsed {
  color: #000;
}

.social-btn-container {
  height: 0px;
}

.icon-button-custom {
  background-color: #ffffff;
}

.creator-profile-form .creator-profile-cont {
  width: 60%;
}

.month-nav-left {
  background-color: #e94560;
  border-radius: 4px;
}

.month-nav-right {
  background-color: #e94560;
  border-radius: 4px;
}

.leads-card {
  border-radius: 8px;
}

.user-filter-icon {
  width: 18px;
}

.trips-conrainer-custom {
  margin-left: 30px;
  margin-right: 30px;
}

#instafeed a {
  margin: 10px;
  display: inline-block;
}

#instafeed img {
  width: 150px;
  height: 150px;
  object-fit: cover;
}

.card-trip .card-profile-image img {
  width: 3.5rem;
}

.blogs-card {
  cursor: pointer;
}

.custom-btn-container {
  width: 50%;
}

.travel-style-modal .modal-footer {
  justify-content: center;
  border-top: none;
}

.testimonilas-container {
  width: 70%;
}

.forgot-text {
  font-size: 0.8rem;
}

.search-trip-card {
  display: flex;
  justify-content: center;
  align-items: center;
}

.trip-destination-filter {
  min-width: 200px;
  max-width: 200px;
  width: 100%;
}

.trip-destination-filter-destination {
  width: 100%;
  max-width: 190px;
  min-width: 190px;
}

.trip-destination-filter-destination .custom-destination-btn {
  width: 100%;
}

.trip-destination-filter-category {
  width: 100%;
  max-width: 155px;
  min-width: 155px;
}

.trip-destination-filter-category .custom-categories-btn {
  width: 100%;
}

.trip-destination-filter-month {
  width: 100%;
  max-width: 195px;
  min-width: 195px;
}

.trip-destination-filter-price {
  width: 100%;
  max-width: 112px;
  min-width: 112px;
}

.trip-destination-filter-price .custom-price-btn {
  width: 100%;
}

.trip-destination-filter-hl {
  width: 100%;
  max-width: 162px;
  min-width: 162px;
}

.testimonial-container {
  background-color: "#FFF5F5";
  margin-top: "60px";
}

.pricerange-btn-container .btn,
.categories-btn-container .btn {
  max-width: 100px;
}

.agent-card-img-container {
  height: 120px;
}

.agent-card-img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  overflow: hidden;
}

.trip-card-container,
.trip-like-container {
  cursor: pointer;
}

.congratulation-image {
  width: 100%;
  /* or any custom size */
  height: 100%;
  object-fit: contain;
}

.dashboard-count-container {
  padding: 15px;
  border: 1.5px solid #e94560;
  border-radius: 4px;
}

.trips-type-count {
  font-size: 60px;
  line-height: 60px;
  font-weight: 800;
}

.draft-listing thead tr {
  background-color: #e94560;
  color: #ffffff;
}

.draft-listing .table-striped > tbody > tr:nth-of-type(even) > * {
  background-color: #e945601f;
}

.draft-listing .table-striped > tbody > tr:hover {
  background-color: #e945604d !important;
}

.traveller-btns-container {
  border: 1.5px solid #e94560;
  border-radius: 4px;
  padding: 25px;
}

.traveler-form-creator {
  border: 1.5px solid #000000;
  border-radius: 4px;
  padding: 10px;
}

.custom-select-container {
  display: flex;
  align-items: center;
}

#select-days {
  height: 50px;
  padding-right: 30px;
}

.checkbox-error-container {
  height: 10px;
}

.table-wrapper {
  overflow-x: auto;
}

.trip-pf span {
  height: 80px;
  width: 209px;
  background: #e94560;
  padding: 15px 15px;
  border-radius: 15px;
}

.live-button {
  text-align: center;
  font-size: 30px;
  line-height: 37px;
  font-weight: 400;
  color: #fff;
}

.alert-text {
  font-size: 30px;
  font-weight: 400;
  line-height: 37px;
  text-align: center;
  color: #fff;
  padding-left: 4rem;
  padding-right: 4rem;
}

.last .simple span {
  font-size: 20px;
  font-weight: 600;
  color: #212529;
}

.object-fit-cover {
  width: 100%;
  height: 250px;
  object-fit: cover;
}

.travel-category-grid {
  white-space: nowrap; 
  overflow: hidden; 
  text-overflow: ellipsis; 
  display: inline-block; 
  max-width: 100%; 
}

@media (max-width: 1500px) {
  .profile-container {
    padding: 0px 0px 0px 0px;
  }
}

@media (max-width: 1440px) {
  /* Creators page image   */
  .imageCollection {
    width: -webkit-fill-available;
  }

  .creator-hero-banner-sub {
    width: 550px;
  }

  .profile-container {
    padding: 0px 0px 0px 0px;
  }

  .custom-padding {
    padding-left: 15%;
    padding-right: 15%;
  }

  .three-image img {
    width: 320px;
    height: 270px;
  }
}

@media (max-width: 1400px) {
  #hero-faq {
    height: 376px;
  }

  .header-btn .btn-primary,
  .custom-btn-size .btn-primary,
  .custom-btn-size.big-font .btn-primary,
  .custom-btn-size .btn-outline-primary,
  .header-btn .btn-outline-primary,
  .custom-tab-nav-items .nav-item .nav-link {
    font-size: 1.15rem;
  }

  .custom-btn-size .btn-primary,
  .custom-btn-size .btn-outline-primary {
    height: 48px;
  }

  .main-heading {
    font-size: 48px;
    line-height: 60px;
  }

  .sub-heading {
    font-size: 26px;
    line-height: 32px;
  }

  .slide-paragraph {
    font-size: 20px;
    line-height: 38px;
  }

  .slide-paragraph span {
    font-size: 20px;
  }

  .footer-nav-list li span {
    font-size: 20px;
  }

  .main-heading-trip-view {
    font-size: 38px;
  }

  .navbar-horizontal .nav-main-menu .navbar-nav-top .nav-link {
    font-size: 18px;
  }

  .navbar-logo .logoMain {
    width: 220px;
    height: auto;
  }

  .custom-profile .custom-profile-btn {
    font-size: 1.1rem;
  }

  .profile-container {
    padding: 0px 0px 0px 0px;
  }

  .travel-category {
    font-size: 22px;
    line-height: 34px;
  }

  .custom-padding {
    padding-left: 15%;
    padding-right: 15%;
  }

  .three-image img {
    width: 320px;
    height: 270px;
  }

  .alert-text {
    font-size: 30px;
    line-height: 37px;
  }
}

@media (max-width: 1200px) {
  #hero-faq {
    height: 312px;
  }

  .filter-container {
    padding: 3rem 0.5rem;
  }

  .header-btn .btn-primary,
  .custom-btn-size .btn-primary,
  .custom-btn-size.big-font .btn-primary,
  .custom-btn-size .btn-outline-primary,
  .header-btn .btn-outline-primary {
    font-size: 1.05rem;
  }

  .custom-btn-size .btn-primary,
  .custom-btn-size .btn-outline-primary {
    height: 44px;
  }

  .header-btn .btn-primary,
  .header-btn .btn-outline-primary {
    width: 80px;
    padding: 5px;
  }

  .navbar-horizontal .nav-main-menu .navbar-nav-top .nav-link {
    font-size: 14px;
  }

  .navbar-logo .logoMain {
    width: 200px;
    height: auto;
  }

  .main-heading {
    font-size: 40px;
    line-height: 52px;
  }

  .sub-heading {
    font-size: 22px;
    line-height: 28px;
  }

  .slide-paragraph {
    font-size: 18px;
    line-height: 34px;
  }

  .slide-paragraph span {
    font-size: 18px;
  }

  .footer-nav-list li span {
    font-size: 18px;
  }

  .three-image img {
    width: 285px;
    height: 232px;
  }

  .custom-padding {
    padding-left: 10%;
    padding-right: 10%;
  }

  .profile-box {
    width: 100%;
  }

  .travel-category {
    font-size: 20px;
    line-height: 32px;
  }

  .hero-heading {
    font-size: 60px;
    line-height: 72px;
  }

  .trips-count div {
    width: 40px;
    font-size: 24px;
    padding: 8px 12px;
  }

  .view-all-trips {
    font-size: 20px;
    line-height: 25px;
  }

  .body-enquire-now {
    padding-left: 3rem;
    padding-right: 3rem;
    padding-bottom: 0rem;
  }

  .alert-text {
    font-size: 27px;
    line-height: 33px;
  }
}

@media (max-width: 1040px) {
  /* Creators page image   */
  .imageCollection {
    display: none;
  }

  .creator-title-details {
    font-size: 50px;
    line-height: 72px;
  }

  .creator-title-details-video {
    font-size: 48px;
    line-height: 76px;
  }

  .creator-profile-form .creator-profile-cont {
    width: 100%;
  }

  .profile-box {
    width: 100%;
  }
}

@media (max-width: 1024px) {
  .creator-hero-banner-sub {
    width: 450px;
  }

  .profile-box {
    width: 100%;
  }

  .travel-style {
    font-size: 16px;
    line-height: 28px;
  }

  .navbar-logo .logoMain {
    width: 150px;
    height: auto;
  }
}

@media (max-width: 992px) {
  #hero-faq {
    height: 267px;
  }

  .hero-heading {
    font-size: 48px;
    line-height: 60px;
  }

  .header-btn .btn-primary,
  .custom-btn-size .btn-primary,
  .custom-btn-size.big-font .btn-primary,
  .custom-btn-size .btn-outline-primary,
  .header-btn .btn-outline-primary,
  .custom-tab-nav-items .nav-item .nav-link {
    font-size: 1.05rem;
  }

  .custom-btn-size .btn-primary,
  .custom-btn-size .btn-outline-primary {
    height: 48px;
  }

  .header-btn .btn-primary,
  .header-btn .btn-outline-primary {
    width: 100px;
    padding: 5px;
  }

  .vertical-nav-items {
    max-width: 200px;
  }

  .nav-main-menu .nav-item.active::after {
    left: 0rem;
    max-width: 200px;
  }

  .main-heading {
    font-size: 32px;
    line-height: 42px;
  }

  .sub-heading {
    font-size: 20px;
    line-height: 26px;
  }

  .slide-paragraph {
    font-size: 16px;
    line-height: 30px;
  }

  .slide-paragraph span {
    font-size: 16px;
  }

  .footer-nav-list li span {
    font-size: 16px;
  }

  .three-image img {
    width: 215px;
    height: 174px;
  }

  .custom-padding {
    padding-left: 15%;
    padding-right: 15%;
  }

  .travel-style {
    font-size: 16px;
    line-height: 28px;
  }

  .travel-category {
    font-size: 18px;
    line-height: 30px;
  }

  .bcm-creator {
    margin: 0rem 0rem 1rem 2rem;
  }

  .trips-count div {
    width: 35px;
    font-size: 22px;
    padding: 6px 10px;
  }

  .view-all-trips {
    font-size: 18px;
    line-height: 23px;
  }

  .navbar-horizontal .nav-main-menu .navbar-nav-top .nav-link {
    font-size: 14px;
  }

  .alert-text {
    font-size: 24px;
    line-height: 30px;
  }

  .navbar-logo .logoMain {
    width: 180px;
    height: auto;
  }
}

@media (max-width: 768px) {
  .container-fluid.cont {
    padding-top: 0rem;
    padding-left: 2.5rem;
    padding-right: 2.5rem;
  }

  #hero-faq {
    height: 265px;
  }

  .hero-heading {
    font-size: 43px;
    line-height: 52px;
  }

  .slide-paragraph {
    font-size: 14px;
    line-height: 28px;
  }

  .slide-paragraph span {
    font-size: 14px;
  }

  .footer-nav-list li span {
    font-size: 14px;
  }

  .profile-container {
    padding: 0px 0px 0px 0px;
  }

  .profile-box {
    width: 100%;
  }

  .container-fluid.cardListing {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }

  .header-btn .btn-primary,
  .custom-btn-size .btn-primary,
  .custom-btn-size.big-font .btn-primary,
  .custom-btn-size .btn-outline-primary,
  .header-btn .btn-outline-primary,
  .custom-tab-nav-items .nav-item .nav-link {
    font-size: 0.95rem;
  }

  .custom-btn-size .btn-primary,
  .custom-btn-size .btn-outline-primary {
    height: 40px;
  }

  .main-heading-trip-view {
    font-size: 33px;
  }

  .main-heading {
    font-size: 28px;
    line-height: 36px;
  }

  .sub-heading {
    font-size: 18px;
    line-height: 24px;
  }

  .creator-title-details {
    font-size: 40px;
    line-height: 60px;
  }

  .creator-title-details-video {
    font-size: 40px;
    line-height: 63px;
  }

  .custom-fs {
    font-size: 12px;
  }

  .creator-hero-banner-sub {
    width: 400px;
  }

  .vetical-second-slide .custom-padding,
  .custom-padding {
    padding-left: 6%;
    padding-right: 6%;
  }

  #hero .play-btn::after {
    border-top: 22px solid transparent;
    border-bottom: 22px solid transparent;
    border-left: 30px solid #fff;
  }

  #hero .play-btn {
    width: 90px;
    height: 90px;
  }

  .three-image img {
    width: 170px;
    height: 138px;
  }

  .travel-style {
    font-size: 15px;
    line-height: 26px;
  }

  .travel-category {
    font-size: 16px;
    line-height: 28px;
  }

  .img-sec-bcm-4 {
    width: 90%;
  }

  .bcm-creator {
    margin: 0rem 0rem 1rem 1rem;
  }

  .creator-sub-heading {
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .trips-count div {
    width: 30px;
    font-size: 20px;
    padding: 5px 8px;
  }

  .view-all-trips {
    font-size: 16px;
    line-height: 21px;
  }

  .congratulation-image {
    width: 100%;
    /* or any custom size */
    height: 100%;
    object-fit: contain;
  }

  .body-enquire-now {
    padding-left: 2rem;
    padding-right: 2rem;
    padding-bottom: 0rem;
  }

  .trip-pf span {
    height: 60px;
    width: 180px;
    padding: 10px 10px;
    border-radius: 12px;
  }

  .live-button {
    text-align: center;
    font-size: 22px;
    line-height: 28px;
    font-weight: 400;
  }

  .custom-pricerange-dropdown .dropdown-menu {
    width: 300px;
  }

  .custom-btn-container {
    width: 75%;
  }

  .navbar-horizontal .nav-main-menu .navbar-nav-top .nav-link {
    font-size: 14px;
  }

  .alert-text {
    font-size: 21px;
    line-height: 26px;
    font-weight: 500;
  }

  .navbar-logo .logoMain {
    width: 160px;
    height: auto;
  }
}

@media (max-width: 576px) {
  .profile-box img {
    width: 14px;
  }

  .profile-box .user-group {
    width: 22px;
  }

  .profile-box span {
    font-size: 14px;
    line-height: 16px;
    margin-left: 5px;
  }

  .travel-style-modal .modal-dialog {
    padding: 0px;
  }

  .travel-style-modal .modal-header {
    padding: 10px;
  }

  .header-title {
    margin-left: 5px;
  }

  .travel-style-sub {
    margin-left: 15px;
  }

  .travel-style-modal .modal-body {
    margin-left: 0px;
    margin-right: 0px;
  }

  .travel-style-modal .modal-profle-bdy {
    margin-left: 0px;
    margin-right: 0px;
  }

  .profile-box .openText {
    margin-left: 0px;
    margin-top: 24px;
    width: 100%;
  }

  .selected-travel-style {
    margin-left: 0px;
  }

  .travel-style {
    font-size: 12px;
    padding: 3px 5px 3px 5px;
  }

  .profile-box .openText.passport,
  .profile-box .openText.dob {
    margin-left: 0px;
  }

  .profile-box .openText.phone {
    margin-left: 0px;
  }

  .footer img {
    width: 280px;
  }

  .navbar-logo .logoMain {
    width: 180px;
    height: auto;
  }

  .header-btn .btn-primary {
    margin-right: 10px;
    padding: 10px;
  }

  .header-btn .btn-primary,
  .custom-btn-size .btn-primary,
  .custom-btn-size.big-font .btn-primary,
  .custom-btn-size .btn-outline-primary,
  .header-btn .btn-outline-primary,
  .custom-tab-nav-items .nav-item .nav-link {
    font-size: 0.85rem;
  }

  .custom-btn-size .btn-primary,
  .custom-btn-size .btn-outline-primary {
    height: 36px;
  }

  #hero-common.hero-trip-destination {
    height: 600px;
  }

  .trip-destination-filter {
    width: 100%;
    max-width: none;
  }

  .creator-hero-banner-sub {
    width: 300px;
  }

  .hero-heading {
    font-size: 32px;
    line-height: 38px;
  }

  .trips-count div {
    width: 25px;
    font-size: 18px;
    padding: 4px 6px;
  }

  .view-all-trips {
    font-size: 14px;
    line-height: 19px;
  }
}

@media (max-width: 575.98px) {
  .navbar-top {
    padding: 0 0.5rem !important;
  }

  .header-btn .btn-primary,
  .custom-btn-size .btn-primary,
  .custom-btn-size.big-font .btn-primary,
  .custom-btn-size .btn-outline-primary,
  .custom-tab-nav-items .nav-item .nav-link {
    font-size: 0.875rem;
  }

  .sign-up-modal .modal-title-custom {
    padding-left: 15px;
  }

  .sign-up-modal .modal-bdy,
  .travel-style-modal .modal-profle-bdy {
    margin-left: 15px;
    margin-right: 15px;
  }

  .trip-destination-filter {
    width: 100%;
    max-width: none;
  }

  .trip-destination-filter {
    width: 100%;
  }

  .trip-destination-filter-destination,
  .trip-destination-filter-category,
  .trip-destination-filter-month,
  .trip-destination-filter-price,
  .trip-destination-filter-hl {
    width: 45%;
    max-width: none;
  }

  .dropdown-categories {
    width: 380px;
  }

  .custom-categories-dropdown .categories-grid {
    grid-template-columns: repeat(3, 1fr);
  }

  .custom-btn-container {
    width: 75%;
  }

  .alert-text {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }
}

@media (max-width: 427px) {
  .main-heading-trip-view {
    font-size: 27px;
    line-height: 37px;
  }

  .creator-title-details-video {
    font-size: 35px;
    line-height: 55px;
  }

  .creator-title-details {
    font-size: 35px;
    line-height: 55px;
  }

  #hero-common.hero-trip-destination {
    height: 700px;
  }

  .trip-destination-filter {
    width: 100%;
    max-width: none;
  }

  .trip-destination-filter,
  .trip-destination-filter-destination,
  .trip-destination-filter-category,
  .trip-destination-filter-month,
  .trip-destination-filter-price,
  .trip-destination-filter-hl {
    width: 100%;
    max-width: none;
  }

  .custom-pricerange-dropdown .dropdown-menu {
    width: 300px;
  }

  .dropdown-categories {
    width: 270px;
  }

  .custom-categories-dropdown .categories-grid {
    grid-template-columns: repeat(2, 1fr);
  }

  .creator-hero-banner-sub {
    width: 250px;
  }

  .custom-padding {
    padding-left: 7%;
    padding-right: 7%;
  }

  #hero .play-btn::after {
    border-top: 15px solid transparent;
    border-bottom: 15px solid transparent;
    border-left: 20px solid #fff;
  }

  #hero .play-btn {
    width: 61px;
    height: 61px;
  }

  #horizontal-swiper .img-grid1 {
    right: -10px;
  }

  #horizontal-swiper .img-grid2 {
    right: -15px;
    left: -20px;
  }

  #horizontal-swiper .img-grid3 {
    top: -12px;
    left: -30px;
  }

  .style-extra-count {
    margin-left: 10px;
  }

  .three-image img {
    width: 130px;
    height: 103px;
  }

  #vertical-swiper .img-grid1 {
    right: -10px;
  }

  #vertical-swiper .img-grid2 {
    bottom: -15px;
    left: -10px;
  }

  #vertical-swiper .img-grid3 {
    top: -8px;
    left: -18px;
  }

  .swiper-slide .swiper-slide-img2 {
    height: 60%;
  }

  .swiper-slide .swiper-slide-img3 {
    height: 45%;
    width: 80%;
    display: block;
    margin-left: auto;
    margin-right: auto;
  }

  .swiper-slide .swiper-slide-img1 {
    height: 50%;
  }

  .creator-sub-heading {
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .trip-pf span {
    height: 50px;
    width: 150px;
    padding: 8px 8px;
    border-radius: 10px;
  }

  .live-button {
    text-align: center;
    font-size: 18px;
    line-height: 24px;
    font-weight: 400;
  }

  .step-navigation {
    gap: 10px;
  }

  .custom-btn-container {
    width: 75%;
  }

  .alert-text {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }
}

@media (max-width: 320px) {
  .main-heading-trip-view {
    font-size: 25px;
    line-height: 40px;
  }

  .trip-destination-filter,
  .trip-destination-filter-destination,
  .trip-destination-filter-category,
  .trip-destination-filter-month,
  .trip-destination-filter-price,
  .trip-destination-filter-hl {
    width: 100%;
    max-width: none;
  }

  .custom-pricerange-dropdown .dropdown-menu {
    width: 300px;
  }

  .custom-categories-dropdown .categories-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 10px;
    padding: 10px;
    margin: 10px;
  }

  .categories-image {
    width: 30px;
    height: 30px;
  }

  .categories {
    width: 110px;
  }

  .categories-image img {
    width: 100%;
  }

  .dropdown-categories {
    width: 270px;
  }

  .vetical-second-slide .custom-padding,
  .custom-padding {
    padding-left: 6%;
    padding-right: 6%;
  }

  #vertical-swiper .img-grid1 {
    right: -20px;
  }

  #vertical-swiper .img-grid3 {
    top: -25px;
    left: -20px;
  }

  #vertical-swiper .img-grid2 {
    bottom: -10px;
  }

  #hero .play-btn::after {
    border-top: 15px solid transparent;
    border-bottom: 15px solid transparent;
    border-left: 20px solid #fff;
  }

  #hero .play-btn {
    width: 61px;
    height: 61px;
  }

  .three-image img {
    width: 130px;
    height: 103px;
  }

  #horizontal-swiper .img-grid1 {
    right: -10px;
  }

  #horizontal-swiper .img-grid2 {
    right: -15px;
    left: -20px;
  }

  #horizontal-swiper .img-grid3 {
    top: -12px;
    left: -30px;
  }

  .travel-style {
    font-size: 14px;
    line-height: 24px;
  }

  .style-extra-count {
    margin-left: 10px;
  }

  .swiper-slide .swiper-slide-img2,
  .swiper-slide .swiper-slide-verti-img2 {
    height: 60%;
  }

  .swiper-slide .swiper-slide-verti-img3 {
    width: 80%;
    height: 50%;
  }

  .creator-sub-heading {
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .body-enquire-now {
    padding-left: 1rem;
    padding-right: 1rem;
    padding-bottom: 0rem;
  }

  .step-connector span {
    border-top-width: 10px;
  }

  .step {
    width: 30px;
    height: 30px;
  }

  .step-number {
    width: 15px;
    height: 15px;
  }

  .numbers-item {
    height: 30px;
    font-size: 15px;
  }

  .trip-pf span {
    height: 50px;
    width: 150px;
    padding: 8px 8px;
    border-radius: 10px;
  }

  .live-button {
    text-align: center;
    font-size: 18px;
    line-height: 24px;
    font-weight: 400;
  }

  .step-navigation {
    gap: 10px;
  }

  .custom-btn-container {
    width: 100%;
  }

  .alert-text {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    font-size: 18px;
    line-height: 22px;
    font-weight: 500;
  }
}
