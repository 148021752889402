.rdrDateDisplayWrapper,
.rdrDefinedRangesWrapper,
.rdrMonthAndYearPickers{
  display: none;
}

.rdrDay.rdrDayActive  .rdrDayNumber span{
  font-size: 10px !important;
  font-weight: 400 !important;
  color: #000000 !important;
}

.rdrDay.rdrDayPassive  .rdrDayNumber span{
  font-size: 10px !important;
  font-weight: 400 !important;
  color: #00000080 !important;
}
.rdrWeekDay{
  font-size: 12px !important;
  font-weight: 500 !important;
  color: #000000 !important;
}

.rdrMonthName{
  font-size: 16px !important;
  font-weight: 800 !important;
  color: #000000 !important
}

.rdrDayToday .rdrDayNumber span:after{
  background: #E94560
}

.rdrMonthAndYearWrapper {
  align-items: center;
  height: 40px;
  padding-top: 0px;
}

.daterange-btn-container {
  padding: 10px;
  margin-bottom: 10px;
}
.rdrNextPrevButton {
  background : #FEF4F4
}
@media (max-width: 576px) {
.rdrMonths{
  flex-direction: column;
}
}