#horizontal-swiper {
  width: 100%;
  height: calc(100vh - 230px);

}

#horizontal-swiper .swiper-pagination {
  right: auto;
  transform: none;
}

.swiper-slide-content {
  width: 100%;
}

.swiper-slide-content .row {
  display: flex;
  flex-wrap: wrap;
}

#horizontal-swiper .swiper-slide {
  display: block;
}

#horizontal-swiper .swiper-pagination-bullet {
  background-color: #ffffff;
  border: 1px solid #E94560;
  opacity: 1;
}

#horizontal-swiper .swiper-pagination-bullet-active {
  background-color: #E94560;
}

#horizontal-swiper .img-grid1 {
  position: relative;
  right: -50px;
  z-index: 9;
}

#horizontal-swiper .img-grid2 {
  position: relative;
  bottom: -30px;
  z-index: 8;
}
 
#horizontal-swiper .img-grid3 {
  position: relative;
  top: -30px;
  left: -25px;
  z-index: 10;
}