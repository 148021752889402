/* Blog CSS */
.category h2 {
  font-size: 24px;
  font-weight: 700;
  margin-bottom: 55px;
  line-height: 29.26px;
}

.category .span {
  background: #E94560;
  padding: 2px 12.04px 2px 12.05px;
  border-radius: 20px;
  opacity: 0px;
  width: 40px;
  height: 40px;
  color: #fff;
  font-weight: 500;
}




.card-heading {
  font-size: 22px;
  font-weight: 700;
  line-height: 26.82px;
}

.card-trip {
  border: 3.5px solid #00000080
}

.blog-detail {
  padding-top: none;
  font-size: 18px;
  font-weight: 400;
  line-height: 30px;
}

.blog-detail .stick {
  font-size: 20px;
  font-weight: 600;
  padding-left: 3px;
  padding-right: 4px;
  line-height: 24.38px;
}

.blog-detail .line {
  padding-left: 4px;
  padding-right: 5px;
}

.f-name {
  color: #FF0000;
  font-size: 18px;
  font-weight: 400;
  line-height: 30px;
}

.footer-card {
  padding: 0.8rem 0.8rem !important;
}


.blog-search-form .form-control{
  width: 100%;
  border: 1px solid #e94560;
  border-radius: 4px;
  font-size: 20px;
  font-weight: 500;
  height: 50px;
  padding: 5px 10px;
}
.blog-search-form .form-control:focus {
    box-shadow: none ;
    border: 1px solid #E94560;
    box-shadow: inset 0 1px 1px rgba(233, 69, 96, 0.075), 0 0 8px rgba(233, 69, 96, 0.6);
}

.other-post h3 {
  font-size: 20px;
  font-weight: 600;
  line-height: 24px;
  margin-bottom: 30px;
}

.card-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  max-width: 1200px;
  margin: 50px auto;
  padding: 18px;
}



.card-text .cate {
  font-size: 14px;
  color: #E94560;
  font-weight: 500;
  padding-left: 12px;
  padding-top: 12px;
}

.h-text .nav-category {
  font-size: 20px;
  font-weight: 600;
  color: #212529;
  padding-left: 12px;
}

.last {
  padding-left: 12px;
}


.last .calendar {
  color: #5ca0e0;
  padding-left: 12px;
}

.categories-blog h2 {
  font-size: 24px;
  font-weight: 700;
  line-height: 29.26px;
}



.category-blog {
  color: #FF0000;
  font-size: 18px;
  font-weight: 400;
  border-bottom: 1px solid #000;
}
.category-blog:hover {
  cursor: pointer;
}

.category-blog .dum-line {
  background: #E94560;
  color: #fff;
  width: 24px;
  height: 24px;
  border-radius: 12px;
  padding: 4.11px, 8.55px, 5.49px, 8.57px;
  text-align: center;
}
.blog-count-bg {
  background-color: #E94560; 
  color: #ffffff; 
  border-radius: 50%; 
  padding: 5px 10px; 
  display: inline-block; 
  min-width: 40px; 
  text-align: center; 
}

.card-title {
  margin-bottom: .5rem !important;
}

.card-title p {
  margin-bottom: 0;
}


/* Top Categories CSS */


.faq-sub-title {
  font-size: 30px;
  font-weight: 400;
  line-height: 36px;
}

.section-para {
  background-color: #f4f4f4;
}

.pera1 {
  font-size: 22px;
  font-weight: 400;
  line-height: 42px;
  color: #000;
  padding-top: 36px;
  padding-bottom: 36px;
}

.rounded-circle-base {
  margin-top: -50px;
}
.rounded-circle-border{
  margin-top: -50px; 
}
.rounded-circle-base  {
  width: 100px;
  height: 100px;
  border-radius: 50%;
}

.rounded-circle-base-dummy  {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  border: 3px solid #afaeae;
  background-color: #e0e0e0;
  margin-top: -50px;
}
.bottomSide-dummy,
.bottomSide-dummy .dummy-text-placeholder{
  max-width: 180px;
  min-width: 180px;
  height: 20px;
}

.rounded-circle-base img {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  border: 3px solid #E94560;
}
.bottomSide h6{
  font-size: 22px;
  font-weight: 700;
  line-height: 27px;
}

.bottomSide p{
  font-size: 18px;
  font-weight: 400;
  line-height: 30px;
}

.text-text p{
  font-size: 18px;
  font-weight: 400;
  line-height: 30px;
}

.trip-location{
  display: flex;
  justify-content: space-between;
  width: 100%;
  top: 0;
  position: absolute;
  z-index: 2;
  padding: 15px; 
}
.trip-destination{
  color: #fff;
}
.trip-like-icon{
  color:  #D9D9D94D;
}

.container-fluid.cont{
  padding-top:0rem ;
  padding-left: 5.5rem;
  padding-right: 5.5rem;
}

/* About CSS */

.card-content{
 padding-left: 12rem!important;
 padding-right: 12rem!important; 
}

.heading-big{
  font-size: 56px;
  line-height: 80px;
  font-weight: 700;
  color: #000000;
  margin-bottom: 4px;
}

.small-heading{
  font-size: 30px;
  font-weight: 400;
  line-height: 37px;
  /* margin-bottom: 15px; */
}

.top-text h6{
 font-size: 56px;
 font-weight: 700;
 line-height: 68px;
 text-align: center;
 color: #E94560; 
 padding-top: 7px;
 padding-bottom: 7px;
}

.center-text p{
  font-size: 22px;
  font-weight: 700;
  line-height: 27px;
  text-align: center;
  color: #000000CC;
}

.bottom-text p{
  font-size: 18px;
  font-weight: 400;
  line-height: 30px;
  text-align: center;
  color: #000000CC;
}

.pera2{
  font-size: 22px;
  font-weight: 400;
  line-height: 42px;
  color: #000000CC; 
  padding-top: 20px;
  padding-bottom: 30px;
}
.about-para{
  font-size: 22px;
  font-weight: 400;
  line-height: 42px;
  color: #000000CC; 
  padding-top: 20px;
}

.name-text{
  font-size: 24px;
  font-weight: 700;
  line-height: 30px;
  color: #979797;
  padding-bottom: 0px;
}

.creator-postion{
  font-size: 18px;
  font-weight: 400;
  line-height: 30px;
  color: #979797;
}

.base-team img{
  width: 92px;
  height: 92px;
  border-radius: 50%;
  margin-left: 10px;
}

/* Trip View CSS */
.trip-main h5{
  font-size: 46px;
  font-weight: 700;
  line-height: 56px;
}

.trip-content{
  margin-top: 5px; 
}
.trip-number{
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-right: 15px;
}

.trip-number p{
 font-size: 18px;
 font-weight: 400;
 line-height: 30px;
}

.price{
font-size: 24px;
font-weight: 700;
line-height: 36px;
/* letter-spacing: -0.015em;
text-align: left; */
}

.hr-line{
 border: 1px solid #E94560; 
}

.main-trip h6{
  font-size: 22px;
  font-weight: 700;
  line-height: 27px;
}

.short-view{
 font-size: 18px;
 font-weight: 400;
 line-height: 30px;
 color: #E94560; 
}

.short-para{
  font-size: 18px;
  font-weight: 400;
  line-height: 30px; 
}

.cateogries-trip{
font-size: 20px;
font-weight: 400;
line-height: 36px;
letter-spacing: -0.015em;
text-align: left;
}

.box-category{
  width: 104.17px;
  height: 115.94px;
  padding: 12px 0px 0px 0px;
  gap: 8px;
  border-radius: 4px 0px 0px 0px;
  border: 1px 0px 0px 0px;
  opacity: 0px;
  border: 1px solid #E94560;
  background: #FEF4F4;     
}
.beach{
  font-size: 16px;
  font-weight: 600;
  line-height: 25px;
  letter-spacing: -0.015em;
  text-align: center;
  color: #E94560;   
}
.category-box{
  width: 108px;
  height: 49px;
  padding: 12px 24px 12px 24px;
  gap: 8px;
  border-radius: 4px 0px 0px 0px;
  border: 1px 0px 0px 0px;
  opacity: 0px;
  border: 1px solid #E94560;
  background: #FEF4F4; 
}
.tag{
  font-size: 20px;
  font-weight: 400;
  line-height: 36px;
  letter-spacing: -0.015em;
  text-align: left; 
}
.line-tag{
/* width: 95px;
height: Hug (42px)px; */
padding: 6px 12px 6px 12px;
gap: 10px;
border-radius: 4px 0px 0px 0px;
opacity: 0px;
background: #c7c7c7;
text-align: center;
}

.line-tag p{
  font-size: 18px;
  font-weight: 400;
  line-height: 30px;
  text-align: center;
  margin-bottom: 0;  
}

.media-photo{
  font-size: 24px;
  font-weight: 700;
  line-height: 29.26px; 
}

.gallary p{
  font-size: 16px;
  font-weight: 700;
  line-height: 16px;
  letter-spacing: -0.015em;   
}

/* Top Creators */
.creator-text{
 margin-bottom: 3rem;
}

.creator-text p{
 font-size: 30px;
 line-height: 37px;
 font-weight: 400; 
}

.small-text{
 font-size: 22px;
 line-height: 42px;
 font-weight: 400;
}


/* About Me CSS */
.Aboutme{
  padding: 24px 30px 24px 30px;
}
.based{
  font-size: 18px;
  font-weight: 400;
  line-height: 30px;
}
.south{
  font-size: 24px;
  font-weight: 700;
  line-height: 30px;
}
.tags{
  margin-top: 2rem;
  display: flex;
  align-items: center;
  /* justify-content: space-between;  */
}
.Taghead{
  font-size: 30px;
  font-weight: 400;
  line-height: 37px;
}
.tagContent{
  display: flex;
  align-items: center;
  justify-content: space-between; 
  margin-left: 5rem;
}
.singleContent{
  background-color: #c7c7c7;
  padding: 6px 12px 6px 12px;
  border-radius: 4px; 
}
.singleContent p{
  font-size: 19px;
  font-weight: 500;
  line-height: 30px;
  text-align: center; 
}
.icons{
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
}

/* CreatorIntrestForm */
.para-section{
  height: 202px;
  background: #E94560;
  display: flex;
  align-items: center;
  justify-content: center;
}




.form-text{
 font-size: 18px!important;
 font-weight: 400!important;
 line-height: 30px!important;
 color: #979797!important; 
}

.form-check input{
  border: 1px solid #E94560!important;
  /* width: 25px;
  height: 25px;
  margin-right: 8px; */
}

/* My Trip CSS */


.trip-plan{
 display: flex;
 align-items: center;
}

.horizontal-tab {
  display: flex;
  justify-content: center;
}



.custom-tab-nav-items-trip {
  padding: 18px 60px!important; 
  border-radius: 5px; 
  background-color: #E945604D;
  border: 1px solid #E94560!important;
  color: #E94560!important;
  padding-top: 10px;
  padding-bottom: 10px;
}

.custom-tab-nav-items-trip:hover {
  background-color: #e2e6ea;
}

.custom-tab-nav-items-trip.active {
  background-color: #E945604D!important;
  color: #E94560!important;
  border: none!important;
}
.trip-text{
 font-size: 24px;
 font-weight: 700; 
 line-height: 30px;
}



/* Responsive */

@media (max-width: 1500px) {
  .input-field .text {
    width: 365px;
  }

  .project {
    width: 365px;
  }


  .para-section{
   height: 270px; 
  }
  

}

@media (max-width: 1300px){
 .center-text p {
  font-size: 20px;
  line-height: 20px;
}
.bottom-text p {
  font-size: 16px;
  line-height: 25px;
}
.name-text {
  font-size: 22px;
  line-height: 26px;
}
.creator-postion {
  font-size: 16px;
  line-height: 26px;
}
}

@media (max-width: 1195px) {
  .input {
    margin-top: 30px;
  }

  .categories-blog {
    margin-top: 30px;
  }

  .project {
    width: auto;
  }


  .rounded-circle-base img{
    width: 80px;
    height: 80px;
  }
  .rounded-circle-border img{
    width: 80px;
    height: 80px;
  }
  .bottomSide h6 {
    font-size: 19px;
    font-weight: 700;
    line-height: 27px;
}
.bottomSide p {
  font-size: 16px;
  font-weight: 400;
  line-height: 30px;
}
.footer-nav-category{
  font-size: 18px;
}
.creator-title {
  font-size: 16px;
}
.text-text p {
  font-size: 17px;
  line-height: 28px;
}

/* About CSS */
.card-content{
  padding-left: 8rem!important;
  padding-right: 8rem!important;
}
.name-text {
  font-size: 18px;
  line-height: 20px;
}
.top-text h6 {
  font-size: 45px;
  line-height: 60px;
}

.faq-sub-title {
  font-size: 22px;
  line-height: 27px;
}
.heading-big {
  font-size: 50px;
  line-height: 72px;
}


}

@media (max-width: 1050px) {
  .rounded-circle-base img {
      width: 70px;
      height: 70px;
  }
  .rounded-circle-border img {
    width: 70px;
    height: 70px;
  }
  .bottomSide h6 {
    font-size: 17px;
    line-height: 23px;
}

.faq-sub-title {
  font-size: 20px;
  line-height: 25px;
}
.footer-nav-category {
  font-size: 16px;
}
.created-by {
  font-size: 13px;
  line-height: 15px;
}
.creator-title {
  font-size: 14px;
}
.text-text p {
  font-size: 15px;
  line-height: 26px;
}
.trip-price {
  font-size: 18px;
  line-height: 28px;
}

/* About */
.card-content {
  padding-left: 5rem !important;
  padding-right: 5rem !important;
}
.center-text p {
  font-size: 18px;
  line-height: 18px;
}
.bottom-text p {
  font-size: 14px;
  line-height: 19px;
}
.heading-big {
  font-size: 45px;
  line-height: 65px;
}
.small-heading {
  font-size: 23px;
  line-height: 30px;
}  

.name-text {
  font-size: 16px;
  line-height: 18px;
}
.base-team img {
  width: 80px;
  height: 80px;
}

/* Trip CSS */
.trip-main h5 {
  font-size: 30px;
  line-height: 40px;
}
.line-tag p {
  font-size: 16px;
  line-height: 25px;
}

}

@media (max-width: 1023px) {
  .input-field .text {
    width: 365px;
  }

.faq-sub-title {
  font-size: 20px;
  font-weight: 400;
  line-height: 30px;
}
.pera1 {
  font-size: 20px;
  line-height: 35px;
}
.rounded-circle-base img {
  width: 95px;
  height: 95px;
}
.rounded-circle-border img{
  width: 95px;
  height: 95px;
}
.bottomSide h6 {
  font-size: 18px;
  line-height: 20px;
}
.bottomSide p {
  font-size: 15px;
  line-height: 25px;
}
.rounded-circle-base {
  margin-top: -40px;
}
.rounded-circle-border {
  margin-top: -40px;
}
.footer-nav-category {
  font-size: 18px;
}
.creator-title {
  font-size: 15px;
  line-height: 18px;
}
.created-by {
  font-size: 14px;
  line-height: 16px;
}

.text-text p {
  font-size: 16px;
  line-height: 25px;
}
.trip-price {
  font-size: 16px;
  line-height: 31px;
}
.cont{
 padding-left: 4rem;
 padding-right: 4rem; 
}
}

@media (max-width: 992px) {

  /* About */
.card-content {
  padding-left: 12rem!important;
  padding-right: 12rem!important;
}
.pera2 {
  font-size: 20px;
  line-height: 32px;
}
}

@media (max-width: 850px){
  /* About */
.card-content {
  padding-left: 8rem!important;
  padding-right: 8rem!important;
}

/* Trip */
  .trip-main h5 {
      font-size: 23px;
      line-height: 33px;
  }
  .beach {
    font-size: 13px;
    line-height: 19px;
}
.box-category {
  width: 101.17px;
  height: 95.94px;
}
.line-tag p {
  font-size: 12px;
  line-height: 19px;
}
.trip-number p {
  font-size: 14px;
  line-height: 15px;
}
.price {
  font-size: 15px;
  line-height: 30px;
}

/* createinterestform */
.para-section{
  height: 290px; 
}

}

@media (max-width: 767px) {


.faq-sub-title {
  font-size: 16px;
  line-height: 26px;
}
.pera1 {
  font-size: 18px;
  line-height: 28px;
}

/* About */
.card-content {
  padding-left: 6.5rem!important;
  padding-right: 6.5rem!important;
}

.trip-main h5 {
  font-size: 18px;
  line-height: 28px;
}
}

@media (max-width: 525px) {

.faq-sub-title {
  font-size: 14px;
  line-height: 20px;
}
.pera1 {
  font-size: 16px;
  line-height: 24px;
}

.cont{
  padding-left: 3.5rem!important;
  padding-right: 3.5rem!important;
}
/* About */
.card-content {
  padding-left: 4rem!important;
  padding-right: 4rem!important;
}
.heading-big {
  font-size: 35px;
  line-height: 45px;
}
.small-heading {
  font-size: 20px;
  line-height: 27px;
}


}

@media (max-width: 436px) {
  .h-text .nav-category {
    font-size: 18px;
  }

  .footer-nav-category {
    font-size: 19px;
  }

  .blog-detail .stick {
    font-size: 16px;
  }

 

  .card-heading {
    font-size: 20px;
    line-height: 24.82px;
  }
  .cont{
    padding-left: 2.5rem!important;
    padding-right: 2.5rem!important;
  }

  /* About */
  .card-content {
    padding-left: 2rem!important;
    padding-right: 2rem!important;
}
  .pera2 {
    font-size: 18px;
    line-height: 30px;
}
.trip-main h5 {
  font-size: 15px;
  line-height: 23px;
}

/* Top Creators */
 .creator-text p{
  font-size: 20px;
  line-height: 28px;
  font-weight: 500;
 }

 /* createinterestform */
.para-section{
  height: 335px; 
}


}

@media (max-width: 407px) {


.cont{
  padding-left: 2.5rem!important;
  padding-right: 2.5rem!important;
}
.category-box {
  width: 92px;
  height: 45px;
}  
}
@media (max-width: 391px) {


  .cont{
    padding-left: 2rem!important;
    padding-right: 2rem!important;
  }
}

@media (max-width: 376px) {


  /* .input-field{
      margin-left: 31px;   
    } */
  .input-field .text {
    width: 275px;
  }



  .card-heading {
    font-size: 18px;
    line-height: 22.82px;
  }

  .text {
    font-size: 16px;
    line-height: 27px;
  }

  .blog-detail {
    font-size: 13px;
  }

  .blog-detail .stick {
    font-size: 13px;
  }

  .h-text .nav-category {
    font-size: 16px;
  }

  .last {
    font-size: 13px;
  }

 

  .last .calendar {
    font-size: 13px;
  }

.cont{
  padding-left: 1.5rem!important;
  padding-right: 1.5rem!important;
}

/* About */
.bottom-text p {
  font-size: 15px;
  line-height: 19px;
}

}

@media (max-width: 321px) {


.faq-sub-title {
  font-size: 12px;
  line-height: 15px;
}
.pera1 {
  font-size: 14px;
  line-height: 22px;
}

.bottomSide h6 {
  font-size: 15px;
  line-height: 17px;
}
.bottomSide p {
  font-size: 12px;
  line-height: 20px;
}
.footer-nav-category {
  font-size: 16px;
}
.creator-title {
  font-size: 12px;
  line-height: 16px;
}
.created-by {
  font-size: 12px;
  line-height: 16px;
}
.text-text p {
  font-size: 14px;
  line-height: 22px;
}
.trip-price {
  font-size: 14px;
  line-height: 28px;
}
.cont{
  padding-left: 1rem!important;
  padding-right: 1rem!important;
}

/* About */
.bottom-text p {
  font-size: 13px;
  line-height: 16px;
}
.creator-postion {
  font-size: 14px;
  line-height: 20px;
}
.heading-big {
  font-size: 25px;
  line-height: 40px;
}

}
