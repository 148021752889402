/* General Styles for Dummy Card */
.dummy-card {
  width: 100%;
  border: 1px solid #e0e0e0;
  border-radius: 0;
  overflow: hidden;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.dummy-image-container {
  width: 100%;
  height: 450px;
  background-color: #f0f0f0;
}

.dummy-image-placeholder {
  width: 100%;
  height: 450px;
  background-color: #e0e0e0;
  animation: pulse 1.5s infinite ease-in-out;
}

.dummy-avatar-placeholder {
  width: 60px;
  height: 60px;
  background-color: #e0e0e0;
  border-radius: 50%;
  animation: pulse 1.5s infinite ease-in-out;
}

.dummy-text-placeholder {
  width: 100%;
  padding: 5px;
  background-color: #e0e0e0;
  border-radius: 4px;
  animation: pulse 1.5s infinite ease-in-out;
  margin-bottom: 10px;
  color: #646464;
}

.dummy-description-placeholder {
  margin-top: 10px;
}

.dummy-price-placeholder {
  margin-top: 20px;
}

.dummy-btn-placeholder {
  margin-top: 30px;
}

.dummy-trip-details {
  background: #f4f4f4;
}

.price-about {
  font-size: 16px;
}

.dummy-price {
  width: 100px;
  height: 30px;
  background-color: #e0e0e0;
  border-radius: 4px;
  display: inline-block;
  animation: pulse 1.5s infinite ease-in-out;
  padding: 5px 5px 5px 10px;
}

.dummy-paragraph {
  width: 100%;
  height: 60px;
  background-color: #e0e0e0;
  border-radius: 4px;
  margin-top: 10px;
  animation: pulse 1.5s infinite ease-in-out;
}

.dummy-category,
.dummy-month {
  width: 100px;
  height: 60px;
  background-color: #e0e0e0;
  border-radius: 4px;
  margin: 5px;
  display: inline-block;
  animation: pulse 1.5s infinite ease-in-out;
}

.dummy-categories {
  width: 150px;
  height: 30px;
  background-color: #e0e0e0;
  border-radius: 4px;
  animation: pulse 1.5s infinite ease-in-out;
  padding: 5px 5px 5px 10px;
}

.dummy-status-placeholder {
  width: 50px;
  height: 20px;
  background-color: #e0e0e0;
  border-radius: 4px;
  animation: pulse 1.5s infinite ease-in-out;
}

.hr-line {
  border: 0;
  border-top: 1px solid #E94560;
  
}

.trip-cate {
  background: #f4f4f4;
  padding: 10px;
}

.cateogries-trip {
  font-size: 18px;
  font-weight: bold;
}

@keyframes pulse {
  0% {
    background-color: #e0e0e0;
  }
  50% {
    background-color: #f0f0f0;
  }
  100% {
    background-color: #e0e0e0;
  }
}
