.swiper {
    width: 100%;
    height: 100%;
  }
  
  .swiper-slide {
    text-align: center;
    font-size: 18px;
    background: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .testimonilas-container .swiper-slide {
    text-align: center;
    font-size: 18px;
    background: transparent;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
    /* object-fit: cover; */
  }
  .swiper-slide .swiper-slide-verti-img3{
    width: 80%;
    height: 80%;
    display: block;
    margin: auto auto auto auto
    
  }