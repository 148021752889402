#vertical-swiper {
  width: 100%;
  height: calc(100vh - 230px);

}

#vertical-swiper .swiper-pagination {
  left: 4rem !important;
  right: auto;
  transform: none;
}

.swiper-slide-content {
  width: 100%;
}

.swiper-slide-content .row {
  display: flex;
  flex-wrap: wrap;
}

#vertical-swiper .swiper-slide {
  display: block;
}

.vrtical-swiper-img-container img {
  height: calc(100vh - 230px);
  object-fit: inherit;
}

.vrtical-swiper-img-container-mobile {
  padding-left: 25% !important;
  padding-right: 25% !important;

  height: calc(100vh - 230px);
}

.vrtical-swiper-img-container-mobile img {
  padding-left: 12.5% !important;
  padding-right: 12.5% !important;
}

.vertical-crousal-last-slide {
  background-color: #FFF5F5;
}

.center-content {
  display: flex;
  align-items: center;
  /* Vertically center */
  justify-content: center;
  /* Horizontally center */
}

.text-container {
  text-align: center;
  /* Center-align the text within this div */
}

.custom-padding {
  padding-left: 25%;
  padding-right: 25%;
}

.text-container p {
  text-align: left;
}

.slide-paragraph {
  font-size: 22px;
  font-weight: 400;
  line-height: 42px;
  padding-top: 20px;
}

.slide-paragraph span {
  font-size: 22px;
}



#vertical-swiper .swiper-pagination-bullet {
  background-color: #ffffff;
  border: 1px solid #000000;
  opacity: 1;
}

#vertical-swiper .swiper-pagination-bullet-active {
  background-color: #000000;
}

.vetical-second-slide {
  background: url(../../../assets/img/travel/crausal/vertical-crousal/pexels-cottonbro-4551839\ 1.png) top center;
  height: calc(100vh - 210px);
  background-size: cover;
  position: relative;
}



.vetical-second-slide .custom-padding {
  padding-left: 25%;
  padding-right: 25%;
}

/* .three-image{
  position: relative;
} */
.three-image img {
  width: 380px;
  height: 310px;

}

#vertical-swiper .img-grid1 {
  position: relative;
  right: -10px;
  z-index: 9;
}

#vertical-swiper .img-grid2 {
  position: relative;
  bottom: -35px;
  left: -50px;
  z-index: 8;
}

#vertical-swiper .img-grid3 {
  position: relative;
  top: -30px;
  left: -75px;
  z-index: 10;
}