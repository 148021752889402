.dummy-hero {
  height: 300px;
  /* Adjust height as necessary */
  position: relative;
  background-color: #f0f0f0;
  /* Fallback color for background */
}

.dummy-banner-placeholder {
  width: 100%;
  height: 100%;
  background-color: #e0e0e0;
  animation: pulse 1.5s infinite ease-in-out;
}

@keyframes pulse {
  0% {
    background-color: #e0e0e0;
  }

  50% {
    background-color: #f0f0f0;
  }

  100% {
    background-color: #e0e0e0;
  }
}

.dummy-container {
  background-color: #f4f4f4;
}

.dummy-section {
  background-color: #f4f4f4;
}

.dummy-main-heading-trip-view {
  width: 100%;
  height: 30px;
  background-color: #e0e0e0;
  animation: pulse 1.5s infinite ease-in-out;
}

.dummy-disabled-link {
  width: 60px;
  height: 20px;
  background-color: #e0e0e0;
  animation: pulse 1.5s infinite ease-in-out;
  display: block;
}

.dummy-blog-container {
  width: 100%;
  height: 350px;
  background-color: #f0f0f0;
}

.dummy-blog-placeholder {
  width: 100%;
  height: 350px;
  background-color: #e0e0e0;
  animation: pulse 1.5s infinite ease-in-out;
}

.dummy-blog-small-placeholder {
  width: 100%;
  height: 180px;
  background-color: #e0e0e0;
  animation: pulse 1.5s infinite ease-in-out;
}
.dummy-agent-container {
  width: 100%;
  height: 150px;
  background-color: #f0f0f0;
}



.dummy-agent-placeholder {
  width: 100%;
  height: 150px;
  background-color: #e0e0e0;
  animation: pulse 1.5s infinite ease-in-out;
}

.dummy-blog-text-placeholder {
  width: 100%;
  padding: 5px;
  background-color: #e0e0e0;
  border-radius: 4px;
  animation: pulse 1.5s infinite ease-in-out;
  margin-bottom: 10px;
  color: #646464;
  height: 22px;
  min-width: 80px;
  display: block;
}

.dummy-button {
  width: 50px;
  height: 30px;
  background-color: #e0e0e0;
  animation: pulse 1.5s infinite ease-in-out;
}

.custom-btn-size-skelton {
  width: 200px;
}

.big-font {
  height: 50px;
}

.dummy-avatar-placeholder-team {
  width: 92px;
  height: 92px;
  background-color: #e0e0e0;
  border-radius: 50%;
  margin-left: 10px;
}

/* Placeholder for the name */
.dummy-name-placeholder {
  width: 150px;
  height: 16px;
  background-color: #e0e0e0;
  border-radius: 4px;
}

/* Placeholder for the position */
.dummy-position-placeholder {
  width: 120px;
  height: 14px;
  background-color: #e0e0e0;
  border-radius: 4px;
}

/* Placeholder for the LinkedIn icon */
.dummy-linkedin-placeholder {
  width: 20px;
  height: 20px;
  background-color: #e0e0e0;
  border-radius: 4px;
  margin-top: 8px;
}

@keyframes pulse {
  0% {
    background-color: #e0e0e0;
  }

  50% {
    background-color: #f0f0f0;
  }

  100% {
    background-color: #e0e0e0;
  }
}