/* General Styles */
.heading {
  background: url("../img/travel/banner/search.jpg") no-repeat;
  background-size: contain;
  width: 100%;
}




.bcm-creator .number-big {
  margin-bottom: 20px;
  color: rgba(0, 0, 0, 0.16);
}



.bcm-creator .brand-title {
  color: rgba(0, 0, 0, 0.8);
  font-family: "Montserrat";
  font-size: 24px;
}

.col-lg-6 .img-sec {
  height: auto;
  width: 600px;
}

.bcm-creator .custom-btn-size .btn-join {
  font-size: 15px;
  height: 30px;
  width: 125px;
  padding: 0px 5px 0px 5px;
}

.trip-plan-custom .trip-plan-container .list-2-container {
  width: 140px;
  z-index: 1;
}

.trip-plan-custom
  .trip-plan-container
  .list-2-container
  .trip-plan-highlight.list-2 {
  background-color: white;
  font-family: "Montserrat";
  font-size: 15px;
  line-height: 1.7;
  font-weight: 800;
  border-radius: 2px;
  border: 1px solid rgba(233, 69, 96, 1);
}

.trip-plan-custom .trip-plan-container .trip-bottom-image .img-3 {
  height: auto;
  width: 450px;
}

.trip-plan-custom .trip-plan-container .img-2 {
  margin-top: 15px;
  margin-left: 20px;
  height: auto;
  width: 450px;
}

.trip-plan-custom .item-1 {
  width: 140px;
  padding-left: 10px;
}

.trip-plan-custom .item-2 {
  width: 140px;
  padding-left: 10px;
}

.trip-plan-custom .item-3 {
  width: 250px;
  padding-left: 10px;
}

@media (max-width: 786px) {
  .row.flex-column-reverse {
    flex-direction: column-reverse;
  }
  .trip-plan-container.earn {
    justify-content: center;
  }
}

@media (min-width: 769px) {


  .bcm-creator .number-big {
    font-size: 80px;
  }

 
  .bcm-creator .brand-title {
    font-size: 24px;
    line-height: 1.7;
  }

  .trip-plan-custom
    .trip-plan-container
    .list-2-container
    .trip-plan-highlight.list-2 {
    padding: 8px;
  }
}

@media (min-width: 428px) and (max-width: 768px) {


  .bcm-creator .number-big {
    font-size: 70px;
  }


  .bcm-creator .brand-title {
    font-size: 22px;
    line-height: 1.8;
  }

  .trip-plan-custom
    .trip-plan-container
    .list-2-container
    .trip-plan-highlight.list-2 {
    font-size: 16px;
    padding: 6px;
  }

  .trip-plan-custom .trip-plan-container .img-2,
  .trip-plan-custom .trip-plan-container .trip-bottom-image .img-3,
  .col-lg-6 .img-sec {
    width: 100%;
    height: auto;
  }
}

@media (max-width: 427px) {
  section.py-3 .bcm-creator {
    margin: 0px 5px;
    padding: 0;
  }

  .bcm-creator .number-big {
    font-size: 60px;
  }





  .bcm-creator .brand-title {
    font-size: 20px;
    line-height: 3;
    margin-bottom: 20px;
  }

  .col-lg-6.trip-plan-custom.section-4 {
    padding: 0px;
  }

  .trip-plan-custom
    .trip-plan-container
    .list-2-container
    .trip-plan-highlight.list-2 {
    font-size: 12px;
    padding: 4px;
    margin-left: 27px;
  }

  .trip-plan-custom .trip-plan-container .img-2 {
    margin: 0;
    padding: 0;
    width: 230px;
  }
  .trip-plan-custom .trip-plan-container .trip-bottom-image .img-3 {
    height: auto;
    width: 230px;
    margin-top: 60px;
  }

  .col-lg-6 .img-sec {
    height: auto;
    width: 350px;
  }
  .col-lg-6 .img-sec-4 {
    height: auto;
    width: 335px;
  }
  .trip-plan-custom .item-1 {
    width: 100px;
    padding-left: 10px;
  }

  .trip-plan-custom .item-2 {
    width: 100px;
    padding-left: 10px;
  }

  .trip-plan-custom .item-3 {
    width: 150px;
    padding-left: 10px;
  }

  .cancelSubmitButtons {
    flex-direction: column;
    align-items: center; 
  }

  .buttonCancel,
  .buttonSubmit {
    width: 80%; 
    margin: 5px 0; 
  } 
}

@media (max-width: 321px) {
  section.py-3 .bcm-creator {
    margin: 0px 5px;
    padding: 0;
  }

  .bcm-creator .number-big {
    font-size: 60px;
  }





  .bcm-creator .brand-title {
    font-size: 20px;
    line-height: 3;
    margin-bottom: 20px;
  }

  .col-lg-6.trip-plan-custom.section-4 {
    padding: 0px;
    /* margin-left: 15px; */
  }

  .trip-plan-custom
    .trip-plan-container
    .list-2-container
    .trip-plan-highlight.list-2 {
    font-size: 12px;
    padding: 4px;
  }

  .trip-plan-custom .trip-plan-container .img-2 {
    margin: 0;
    padding: 0;
    width: 160px;
  }
  .trip-plan-custom .trip-plan-container .trip-bottom-image .img-3 {
    height: auto;
    width: 160px;
    margin-top: 75px;
  }

  .col-lg-6 .img-sec {
    height: auto;
    width: 250px;
  }
  .col-lg-6 .img-sec-4 {
    height: auto;
    width: 230px;
  }

  .trip-plan-custom .item-1 {
    width: 100px;
    padding-left: 10px;
  }

  .trip-plan-custom .item-2 {
    width: 100px;
    padding-left: 10px;
  }

  .trip-plan-custom .item-3 {
    width: 150px;
    padding-left: 10px;
  }
}













/*top destination - southAfrica*/

.container-fluid.headingSouthAfrica {
  position: relative;
  margin: 0;
  padding: 0;
}

.southAfricaheading {
  width: 100%;
  height: auto;
}



.southAfricaHeadingSubText {
  font-family: Montserrat;
  font-size: 30px;
  font-weight: 400;
  line-height: 36.57px;
  text-align: left;
}

.text-block {
  position: absolute;
  bottom: 40px;
  left: 90px;
  color: white;
  padding: 0px 0px;
  margin: 0px 0px;
}

.brand-title.southAfricaLocation {
  font-weight: 700;
  padding: 20px 0px 0px 40px;
}

.container-fluid.southAfricaDetails {
  width: 95%;
}

.brand-title.southAfricaText {
  font-size: 22px;
  line-height: 42px;
  background-color: rgba(244, 244, 244, 1);
  padding: 30px;
}
.boldPara{
  font-weight: 700;
  color: rgba(0, 0, 0, 1);
}

.secondPara{
  font-weight: 400;
  color: rgba(0, 0, 0, 0.8);
}

.container-fluid.travelCreatorsCardSSection{
    width: 95%;
}



.travelCreatorsCardSSectionSubHeading{
font-family: Montserrat;
font-size: 30px;
font-weight: 400;
line-height: 36.57px;
text-align: left;
color: rgba(0, 0, 0, 0.8);
}

.travelCreatorsCardSSectionPagination{
gap: 12px;
opacity: 0px;
}

.paginationNo{
width: 56px;
height: 51px;
gap: 0px;
border-radius: 4px;
background: rgba(233, 69, 96, 1);
color: white;
}

.paginationNumbering {
  font-family: Montserrat;
  font-size: 20px;
  font-weight: 600;
  line-height: 50px;
  text-align: right;
  color: white;
  margin-left: 23px;
}

.paginationNumbering:hover {
    text-decoration: none; 
    color: inherit; 
}

.col-md-9.col-lg-9.brand-title.flex-grow-1{
  padding-top: 0px;
}

.col-md-3.col-lg-3.d-flex.justify-content-end.flex-shrink-0{
  margin-bottom: 70px;
}

.viewAllTrips{
font-family: Montserrat;
font-size: 20px;
font-weight: 600;
/* line-height: 24.38px; */
text-align: right;
color: rgba(233, 69, 96, 1);
}

.brand-title-container.align-items-center.lineView{
  padding-bottom: 15px;
}

.viewAllTrips:hover {
  text-decoration: none; 
  color: inherit; 
}

.cardBody{
border: 1px solid rgba(0, 0, 0, 0.5);
height: 100%;
display: flex;
flex-direction: column;
justify-content: space-between;
}

.card-img-top.cardppImg {
gap: 0px;
opacity: 0px;
}


.image-container {
  position: relative;
  display: inline-block;
}

.cardImg {
  width: 100%;
}

.text-overlay {
  position: absolute;
  bottom: 10px;
  right: 10px;
  color: rgba(255, 255, 255, 1);
  font-family: Montserrat;
  font-size: 18px;
  font-weight: 400;
  line-height: 30px;
  text-align: right;
}



.cardTitle{
  font-weight: 700;
  line-height: 25px;
  text-align: left;
  font-size: 1rem;
  margin: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.cardText.card-text{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  font-size: 18px;
  font-weight: 400;
  line-height: 30px;
  text-align: left;
  flex-grow: 1;
  }
  
  .cardTitleWrapper {
    min-height: 40px;
  }
.cardButton {
  width: 100%;
  height: auto;
  gap: 0px;
  border-radius: 6px;
  border: 2px solid transparent;
  background-color: rgba(233, 69, 96, 1);
  color: white;
  opacity: 1; /* Ensure it's visible */
  font-family: Montserrat;
  font-size: 20px;
  font-weight: 600;
  line-height: 24.38px;
  text-align: center;
}

.bottomButton{
/* height: 52px; */
gap: 0px;
border-radius: 6px 6px 6px 6px;
opacity: 0px;
background-color: rgba(233, 69, 96, 1);
color: white;
font-family: Montserrat;
font-size: 20px;
font-weight: 600;
line-height: 24.38px;
text-align: center;
}

/* Responsive Styles */
@media (max-width: 1200px) {

  .text-block {
    bottom: 35px;
    font-size: 0.9em; 
    width: 90%;
  }

  .southAfricaHeadingSubText {
    font-size: 26px;
    line-height: 32px;
  }



  .travelCreatorsCardSSectionSubHeading {
    font-size: 26px;
    line-height: 32px;
  }
  .form-label.labels{
    padding-left: -1px!important;
  }
}

@media (max-width: 1025px){



  .southAfricaHeadingSubText {
    font-size: 30px;
    line-height: 35px;
  }

  .text-block {
    bottom: 50px;
    font-size: 0.8em;
    width: 50%;
  }

  .cardBody.card-body{
    padding: 5px;
  } 

  .text-block {
    bottom: 50px;
    font-size: 0.8em; 
    width: 50%;
    left: 90px;
  }



  .travelCreatorsCardSSectionSubHeading {
    font-size: 24px;
    line-height: 30px;
  }
  .cardTitle {
    font-family: Montserrat;
    font-size: 18px;
    font-weight: 700;
    line-height: 24.82px;
    text-align: left;
  }
  .container.traveller{
      /* margin: 0px;
      padding: 0px; */
      line-height: 15px;
  }
  .cardTripDescription{
    line-height: 25px;
  }
  .cardDestinationName{
    font-size: small;
  }
}

@media (max-width: 992px) {


  .southAfricaHeadingSubText {
    font-size: 24px;
    line-height: 30px;
  }

  .text-block {
    bottom: 30px;
    font-size: 0.8em; 
    width: 80%;
  }



  .travelCreatorsCardSSectionSubHeading {
    font-size: 24px;
    line-height: 30px;
  }
  .cardDestinationName{
    font-size: small;
  }
}

@media (max-width: 768px) {
 

  .southAfricaHeadingSubText {
    font-size: 22px;
    line-height: 28px;
  }

  .text-block {
    bottom: 60px;
    width: 70%;
}

  .travelCreatorsCardSSectionPagination {
    gap: 8px;
  }


  .travelCreatorsCardSSectionSubHeading {
    font-size: 20px;
    line-height: 26px;
  }

  .brand-title.southAfricaText {
    font-size: 18px;
    line-height: 30px;
    padding: 20px;
  }
  .cardDestinationName{
    font-size: small;
  }
}

@media (max-width: 576px) {
  
.southAfricaHeadingImage{
    height: 350px !important;
  }

.brand-title.southAfricaLocation{
    font-size: 15px;
    padding: 0px;
    line-height: 40px;
  }


.southAfricaHeadingSubText {
      font-size: 17px;
  }

.text-block {
    left: 0px;
    bottom: 35px;
    padding-left: 15px;
    font-size: 13px;
    width: 100%;
  }

.col-md-9.col-lg-9.brand-title.flex-grow-1 {
    padding-top: 0px;
    line-height: 20px;
}

.card-body.cardBody{
    padding: 10px;
  }

.travelCreatorsCardSSectionSubHeading {
    font-size: 18px;
    line-height: 24px;
  }

.cardTitle {
    font-family: Montserrat;
    font-size: 17px;
    font-weight: 700;
    line-height: 15.82px;
    text-align: left;
}

.cardTripDescription{
  font-size: 15px;
  line-height: 24px;
}

.cardDestinationName{
  font-size: small;
}

  .brand-title.southAfricaText {
    font-size: 16px;
    line-height: 28px;
    padding: 15px;
  }
}












/* Get in touch */


.container.getInTouch{
  width: 70%;
  padding: 20px;
}


.form-label.labels{
  text-transform: none;
  font-family: Montserrat;
  font-size: 18px;
  font-weight: 500;
  line-height: 30px;
  text-align: left;
  padding-left: 0px;
  margin-bottom: 5px;
  color: #000000CC!important;
}

.form-control.inputbox{
padding: 18px 12px 18px 12px;
gap: 10px;
border-radius: 4px; 
opacity: 0px;
border: 1px solid rgba(151, 151, 151, 1)
}

.inputbox::placeholder {
  font-family: Montserrat;
  font-size: 12px;
  font-weight: 400;
  line-height: 14px;
  color: rgba(0, 0, 0, 0.8);
  text-align: left;
}
.form-control.inputbox.messageArea{
height: 123px;
padding: 18px 12px 18px 12px;
gap: 10px;
border-radius: 4px ;
border: 1px solid rgba(151, 151, 151, 1);
opacity: 0px;
}

.buttonCancel{
font-size: 20px;
font-weight: 600;
line-height: 24.38px;
text-align: center;
color: rgba(233, 69, 96, 1);
background-color: white;
border: 1px solid rgba(233, 69, 96, 1);
border-radius: 6px;
padding: 13px 50px;
}

.buttonSubmit{
  font-size: 20px;
  font-weight: 600;
  line-height: 24.38px;
  text-align: center;
  color: white;
  background-color: rgba(233, 69, 96, 1);
  border: 1px solid rgba(233, 69, 96, 1);
  border-radius: 6px;
  padding: 13px 50px;
  }

  .cancelSubmituttons{
    padding: 0px 10px 0px 10px;
  }













  /* lisitingCSS */

  .listingContentBox{
    width: 1812px;
  height: 114px;
  top: 230px;
  left: 54px;
  gap: 0px;
  border-radius: 7px 0px 0px 0px;
  border: 1px 0px 0px 0px;
  opacity: 0px;
  border: 1px solid rgba(233, 69, 96, 1)
  }

  .box{
    width: 1812px;
    height: 114px;
    top: 230px;
    left: 54px;
    gap: 0px;
    border-radius: 7px 0px 0px 0px;
    border: 1px 0px 0px 0px;
    opacity: 0px;
  }
  .container-fluid.cardListing{
    padding-left: 2.5rem;
    padding-right: 2.5rem;
  }

  .lisitingLeftSide{
  font-family: Montserrat;
  font-size: 18px;
  font-weight: 400;
  line-height: 30px;
  text-align: left;
  color: rgba(233, 69, 96, 1);
  }

  .lisitingRightSide{
  font-family: Montserrat;
  font-size: 18px;
  font-weight: 400;
  line-height: 30px;
  text-align: right;
  color: rgba(0, 0, 0, 1);
  }

