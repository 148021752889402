.custom-destination-dropdown .dropdown-menu {
    width: 250px; /* Adjust width as needed */
  }
  
  .custom-destination-btn {
    display: flex;
    align-items: center;
  }
  
  .destination {
    display: flex;
    justify-content: space-between;
    padding: 10px;
    cursor: pointer;
  }
  
  .destination-card .destination.selected {
    background-color: #E94560; /* Change to your desired color */
    color: #ffffff;
  }
  
  .destination-btn-icon {
    margin-left: auto;
  }
  
  .destination-btn-container {
    padding:10px 0px 10px 0px
  }
  
  @media (max-width: 576px) {
    
}